import type { SVGAttributes } from 'react';
import { PortIconSVG } from 'atto-svgs';
import React from 'react';

import { Icon } from '../../assets/Icon/Icon';
import { FocusRing } from '../../common/focus_rings';
import { colors, darkThemeSelector, styled } from '../../stitches.config';

const PortSVG = styled('svg', {
  position: 'absolute',
  zIndex: 0,
  top: 0,
  left: 0,
  display: 'flex',
  overflow: 'visible',
  strokeWidth: '2px',
  paintOrder: 'stroke',
  variants: {
    active: {
      true: {},
      false: {},
    },
    disabled: {
      true: {},
      false: {},
    },
    orientation: {
      up: {},
      down: {
        transform: 'rotate(180deg)',
      },
    },
    speed: {
      10: {},
      100: {},
      1000: {},
      2500: {},
      10000: {},
    },
    status: {
      connected: {},
      disconnected: {},
      disabled: {},
      error: {},
    },
    variant: {
      simple: {
        width: '22px',
        height: '20px',
      },
      detailed: {
        width: '32px',
        height: '28px',
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      css: {
        opacity: 0.7,
        color: colors.hardwareBgDisabledLight,
        stroke: colors.hardwareStrokeDisabledLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgDisabledDark,
          stroke: colors.hardwareStrokeDisabledDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 10,
      css: {
        color: colors.hardwareBgAttentionLight,
        stroke: colors.hardwareStrokeAttentionLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgAttentionDark,
          stroke: colors.hardwareStrokeAttentionDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 100,
      css: {
        color: colors.hardwareBgAttentionLight,
        stroke: colors.hardwareStrokeAttentionLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgAttentionDark,
          stroke: colors.hardwareStrokeAttentionDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 1000,
      css: {
        color: colors.hardwareBgPositiveLight,
        stroke: colors.hardwareStrokePositiveLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgPositiveDark,
          stroke: colors.hardwareStrokePositiveDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 2500,
      css: {
        color: colors.hardwareBgPositiveLight,
        stroke: colors.hardwareStrokePositiveLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgPositiveDark,
          stroke: colors.hardwareStrokePositiveDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 10000,
      css: {
        color: colors.hardwareBgAlternativeLight,
        stroke: colors.hardwareStrokeAlternativeLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgAlternativeDark,
          stroke: colors.hardwareStrokeAlternativeDark,
        },
      },
    },
    {
      disabled: false,
      status: 'disconnected',
      css: {
        color: colors.hardwareBgNeutralLight,
        stroke: colors.hardwareStrokeNeutralLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgNeutralDark,
          stroke: colors.hardwareStrokeNeutralDark,
        },
      },
    },
    {
      disabled: false,
      status: 'error',
      css: {
        color: colors.hardwareBgNegativeLight,
        stroke: colors.hardwareStrokeNegativeLight,
        [darkThemeSelector]: {
          color: colors.hardwareBgNegativeDark,
          stroke: colors.hardwareStrokeNegativeDark,
        },
      },
    },
  ],
});

const PortThroughput = styled(Icon, {
  width: '$12',
  height: '$12',
  margin: '0 auto',
  padding: '1px',
  borderRadius: '3px',
  variants: {
    active: {
      true: {},
      false: {},
    },
    disabled: {
      true: {},
      false: {},
    },
    speed: {
      10: {},
      100: {},
      1000: {},
      2500: {},
      10000: {},
    },
    status: {
      connected: {},
      disconnected: {},
      disabled: {},
      error: {},
    },
  },
  compoundVariants: [
    {
      disabled: true,
      css: {
        opacity: 0.7,
        backgroundColor: colors.hardwareBgDisabledLight,
        color: colors.hardwareBgDisabledLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareBgDisabledDark,
          color: colors.hardwareBgDisabledDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 10,
      css: {
        backgroundColor: colors.hardwareContentAttentionLight,
        color: colors.hardwareBgAttentionLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareContentAttentionDark,
          color: colors.hardwareBgAttentionDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 100,
      css: {
        backgroundColor: colors.hardwareContentAttentionLight,
        color: colors.hardwareBgAttentionLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareContentAttentionDark,
          color: colors.hardwareBgAttentionDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 1000,
      css: {
        backgroundColor: colors.hardwareContentPositiveLight,
        color: colors.hardwareBgPositiveLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareContentPositiveDark,
          color: colors.hardwareBgPositiveDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 2500,
      css: {
        backgroundColor: colors.hardwareContentPositiveLight,
        color: colors.hardwareBgPositiveLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareContentPositiveDark,
          color: colors.hardwareBgPositiveDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 10000,
      css: {
        backgroundColor: colors.hardwareContentAlternativeLight,
        color: colors.hardwareBgAlternativeLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareContentAlternativeDark,
          color: colors.hardwareBgAlternativeDark,
        },
      },
    },
    {
      disabled: false,
      status: 'disconnected',
      css: {
        backgroundColor: colors.hardwareContentNeutralLight,
        color: colors.hardwareBgNeutralLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareContentNeutralDark,
          color: colors.hardwareBgNeutralDark,
        },
      },
    },
    {
      disabled: false,
      status: 'error',
      css: {
        backgroundColor: colors.hardwareContentNegativeLight,
        color: colors.hardwareBgNegativeLight,
        [darkThemeSelector]: {
          backgroundColor: colors.hardwareContentNegativeDark,
          color: colors.hardwareBgNegativeDark,
        },
      },
    },
  ],
});

const PortBlocking = styled(PortThroughput, {
  padding: '$2',
});

const PortPropertiesStart = styled('div', {
  display: 'flex',
  paddingLeft: '$4',
});

const PortPropertiesEnd = styled('div', {
  display: 'flex',
  paddingRight: '$2',
});

const PortProperties = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  minHeight: '$12',
  variants: {
    variant: {
      detailed: {
        justifyContent: 'space-between',
      },
      simple: {
        justifyContent: 'center',
      },
    },
  },
});

const PortNumber = styled('span', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '18px',
  fontFamily: '$mono',
  fontSize: '10px',
  fontWeight: '$bold',
  lineHeight: '12px',
});

const PortPoE = styled(Icon, {
  display: 'flex',
  width: '$12',
  height: '$12',
});

const PortContents = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
  width: '100%',
  height: '100%',
  variants: {
    active: {
      true: {},
      false: {},
    },
    disabled: {
      true: {},
      false: {},
    },
    orientation: {
      up: {
        flexDirection: 'column',
        justifyContent: 'end',
      },
      down: {
        flexDirection: 'column-reverse',
        justifyContent: 'start',
      },
    },
    speed: {
      10: {},
      100: {},
      1000: {},
      2500: {},
      10000: {},
    },
    status: {
      connected: {},
      disconnected: {},
      disabled: {},
      error: {},
    },
    variant: {
      detailed: {
        padding: '5px 0',
      },
      simple: {},
    },
  },
  compoundVariants: [
    {
      disabled: true,
      css: {
        color: colors.hardwareContentDisabledLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentDisabledDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 10,
      css: {
        color: colors.hardwareContentAttentionLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentAttentionDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 100,
      css: {
        color: colors.hardwareContentAttentionLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentAttentionDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 1000,
      css: {
        color: colors.hardwareContentPositiveLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentPositiveDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 2500,
      css: {
        color: colors.hardwareContentPositiveLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentPositiveDark,
        },
      },
    },
    {
      disabled: false,
      status: 'connected',
      speed: 10000,
      css: {
        color: colors.hardwareContentAlternativeLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentAlternativeDark,
        },
      },
    },
    {
      disabled: false,
      status: 'disconnected',
      css: {
        color: colors.hardwareContentNeutralLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentNeutralDark,
        },
      },
    },
    {
      disabled: false,
      status: 'error',
      css: {
        color: colors.hardwareContentNegativeLight,
        [darkThemeSelector]: {
          color: colors.hardwareContentNegativeDark,
        },
      },
    },
  ],
});

const PortContainer = styled('div', FocusRing, {
  position: 'relative',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'stretch',
  variants: {
    variant: {
      simple: {
        width: '22px',
        maxWidth: '22px',
        minWidth: '22px',
        height: '$20',
        maxHeight: '$20',
        minHeight: '$20',
        borderRadius: '$4',
      },
      detailed: {
        width: '$32',
        maxWidth: '$32',
        minWidth: '$32',
        height: '$28',
        maxHeight: '$28',
        minHeight: '$28',
        borderRadius: '$6',
      },
    },
  },
});

const PortIcons = {
  ethernet: PortIconSVG.Ethernet,
  sfp: PortIconSVG.SFP,
};

export type PortIconName = keyof typeof PortIcons;

export type PortPropOnClick = (event: any) => void;
export type PortPropOrientation = 'down' | 'up';
export type PortPropPort = 'ethernet' | 'sfp';
export type PortPropSpeed = 10 | 100 | 1000 | 2500 | 10000;
export type PortPropStatus = 'connected' | 'disconnected' | 'disabled' | 'error';
export type PortPropVariant = 'detailed' | 'simple';

export type PortProps = {
  active?: boolean;
  blocking?: boolean;
  connection?: {
    active?: boolean;
    hardware:
      | 'access-point'
      | 'isp'
      | 'pdu'
      | 'power-distribution-unit'
      | 'security-appliance'
      | 'switch';
    label: React.ReactNode;
    onClick?: PortPropOnClick;
    side?: 'bottom' | 'top';
  };
  disabled?: boolean;
  number?: number;
  onClick?: PortPropOnClick;
  orientation?: PortPropOrientation;
  poe?: boolean;
  port: PortPropPort;
  speed?: PortPropSpeed;
  uplink?: boolean;
  status?: PortPropStatus;
  variant?: PortPropVariant;
};

export function Port({
  active = false,
  blocking = false,
  connection,
  disabled = false,
  number,
  onClick,
  orientation = 'up',
  poe = false,
  port,
  speed = 1000,
  uplink = false,
  status = 'disconnected',
  variant = 'detailed',
  ...remaining
}: PortProps) {
  const NamedIcon = PortIcons[port as PortIconName] as
    | React.ComponentType<SVGAttributes<SVGSVGElement>>
    | undefined;
  const simplified = variant === 'simple';
  const isBlocked = status === 'error' || blocking;
  return (
    <PortContainer tabIndex={onClick && 0} data-port={port} variant={variant} {...remaining}>
      <PortContents
        onClick={onClick}
        role={onClick && 'button'}
        active={active}
        disabled={disabled}
        orientation={orientation}
        speed={speed}
        status={status}
        variant={variant}
      >
        <PortProperties variant={variant}>
          {simplified && <PortNumber>{number}</PortNumber>}
          {!simplified && !disabled && (
            <>
              <PortPropertiesStart>
                {uplink && !isBlocked && (
                  <PortThroughput
                    active={active}
                    disabled={disabled}
                    speed={speed}
                    status={status}
                    icon="arrow-up"
                  />
                )}
                {isBlocked && (
                  <PortBlocking
                    active={active}
                    disabled={disabled}
                    speed={speed}
                    status={status}
                    icon="block"
                  />
                )}
              </PortPropertiesStart>
              <PortPropertiesEnd>
                {!simplified && poe && <PortPoE icon="lightning" />}
              </PortPropertiesEnd>
            </>
          )}
        </PortProperties>
      </PortContents>
      <PortSVG
        as={NamedIcon}
        active={active}
        disabled={disabled}
        orientation={orientation}
        speed={speed}
        status={status}
        variant={variant}
      />
    </PortContainer>
  );
}
