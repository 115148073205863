import React from 'react';

import type { GutterProp } from '../../common/sizing';
import type { StackSpacing } from '../../utilities/Stack/Stack';
import { sizing } from '../../common/sizing';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { VStack } from '../../utilities/Stack/VStack';

const ColumnContainer = styled(VStack, {
  height: 'fit-content',

  variants: {
    gutter: {
      all: {
        padding: sizing.squish,
      },
      bottom: {
        paddingBottom: sizing.ends,
      },
      horizontal: {
        padding: sizing.sidesOnly,
      },
      left: {
        paddingLeft: sizing.sides,
      },
      none: {},
      right: {
        paddingRight: sizing.sides,
      },
      top: {
        paddingTop: sizing.ends,
      },
      vertical: {
        padding: sizing.endsOnly,
      },
    },
    width: {
      full: {
        width: '100%',
      },
      half: {
        width: '50%',
      },
      quarter: {
        width: '25%',
      },
      remaining: {
        width: '100%',
      },
      summary: {
        width: '$360',
        maxWidth: '$360',
        minWidth: '$360',
      },
      third: {
        width: '33%',
      },
    },
  },
  compoundVariants: [
    {
      width: 'summary',
      css: {
        '@notDesktop': {
          width: '100%',
          maxWidth: '100%',
          minWidth: 0,
        },
      },
    },
  ],
});

export type ColumnProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Set the spacing between the children of `Column`.
   */
  spacing?: StackSpacing;
  /**
   * Pre-determined values for the width allowing for consistent replication of column styles.
   */
  width?: 'full' | 'half' | 'quarter' | 'remaining' | 'summary' | 'third';
};

export function Column({
  children,
  gutter = 'none',
  spacing,
  width = 'full',
  ...remaining
}: ColumnProps) {
  return (
    <ColumnContainer gutter={gutter} spacing={spacing} width={width} {...remaining}>
      {children}
    </ColumnContainer>
  );
}

const columnsTemplateStyles = {
  display: 'flex',

  '@notDesktop': {
    flexDirection: 'column-reverse',
  },

  '@desktop': {
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',

    [`& > ${ColumnContainer}`]: {
      height: '100%',
    },
  },
};

const columnsStripWidth = sizing.primary * 2 + 200;

export const ColumnsContainer = styled('div', {
  width: '100%',

  variants: {
    direction: {
      forward: {},
      reverse: {
        '@notDesktop': {
          flexDirection: 'column-reverse',
          justifyContent: 'flex-end',
        },

        '@desktop': {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        },
      },
    },
    scroll: {
      independent: {},
      none: {},
      unified: {
        overflow: 'auto',
      },
    },
    template: {
      object: {
        '@notDesktop': {
          display: 'flex',
          flexDirection: 'column-reverse',
        },

        '@desktop': {
          display: 'grid',
          gridTemplateColumns: `$${columnsStripWidth} 1fr`,
          gridTemplateAreas: '"object-strip object-metrics"',
          minHeight: 'fit-content',

          [`& ${ColumnContainer}:first-child`]: {
            '@desktop': {
              gridArea: 'object-strip',
              height: '100%',
              strokeRight: colors.strokeApplicationLight,

              [darkThemeSelector]: {
                strokeRight: colors.strokeApplicationDark,
              },
            },
          },

          [`& ${ColumnContainer}:last-child`]: {
            '@desktop': {
              gridArea: 'object-metrics',
              height: '100%',
              strokeLeft: colors.strokeApplicationLight,

              [darkThemeSelector]: {
                strokeLeft: colors.strokeApplicationDark,
              },
            },
          },
        },
      },
      flex: {
        display: 'flex',

        '@notDesktop': {
          flexDirection: 'column',
        },

        '@desktop': {
          flexDirection: 'row',
          height: '100%',
        },
      },
      grid: {
        display: 'grid',
        gridTemplateRows: 'repeat(auto-fit, minmax(0, min-content))',
      },
      'even-split': {
        ...columnsTemplateStyles,

        [`& > ${ColumnContainer}`]: {
          flex: '1 0 50%',
          minWidth: '$420',
          maxWidth: '100%',
        },
      },
      'wide-narrow': {
        ...columnsTemplateStyles,

        [`& > ${ColumnContainer}:first-child`]: {
          flex: 999,
          minWidth: '$420',
          maxWidth: '100%',
        },

        [`& > ${ColumnContainer}:last-child`]: {
          flex: 1,
          minWidth: '$360',
          maxWidth: '100%',
        },
      },
      'narrow-wide': {
        ...columnsTemplateStyles,

        '@notDesktop': {
          flexDirection: 'column',
        },

        [`& > ${ColumnContainer}:first-child`]: {
          flex: 1,
          minWidth: '$360',
          maxWidth: '100%',
        },

        [`& > ${ColumnContainer}:last-child`]: {
          flex: 999,
          minWidth: '$420',
          maxWidth: '100%',
        },
      },
    },
  },

  compoundVariants: [
    {
      scroll: 'independent',
      template: 'flex',
      css: {
        [`& > ${ColumnContainer}`]: {
          overflow: 'auto',
          height: '100%',
        },
      },
    },
    {
      scroll: 'independent',
      template: 'even-split',
      css: {
        [`& > ${ColumnContainer}`]: {
          overflow: 'auto',
          height: '100%',
        },
      },
    },
    {
      scroll: 'independent',
      template: 'narrow-wide',
      css: {
        [`& > ${ColumnContainer}`]: {
          overflow: 'auto',
          height: '100%',
        },
      },
    },
    {
      scroll: 'independent',
      template: 'wide-narrow',
      css: {
        [`& > ${ColumnContainer}`]: {
          overflow: 'auto',
          height: '100%',
        },
      },
    },
    {
      scroll: 'independent',
      template: 'object',
      css: {
        [`& > ${ColumnContainer}`]: {
          overflow: 'auto',
          height: '100%',
        },
      },
    },
    {
      template: 'even-split',
      direction: 'reverse',
      css: {
        '@notDesktop': {
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        '@desktop': {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        },
      },
    },
    {
      template: 'narrow-wide',
      direction: 'reverse',
      css: {
        '@notDesktop': {
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        '@desktop': {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        },
      },
    },
    {
      template: 'wide-narrow',
      direction: 'reverse',
      css: {
        '@notDesktop': {
          flexDirection: 'column',
          justifyContent: 'flex-end',
        },
        '@desktop': {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-end',
        },
      },
    },
  ],
});

export type ColumnsProps = {
  children?: React.ReactNode;
  direction?: 'forward' | 'reverse';
  minWidth?: number;
  scroll?: 'independent' | 'none' | 'unified';
  template?: 'flex' | 'grid' | 'even-split' | 'narrow-wide' | 'wide-narrow' | 'object';
};

export function Columns({
  children,
  direction,
  minWidth = 360,
  scroll = 'none',
  template,
  ...remaining
}: ColumnsProps) {
  return (
    <ColumnsContainer
      direction={direction}
      scroll={scroll}
      template={template}
      style={
        template === 'grid'
          ? { gridTemplateColumns: `repeat(auto-fit, minmax(${minWidth}px, 1fr))` }
          : {}
      }
      {...remaining}
    >
      {children}
    </ColumnsContainer>
  );
}
