import type * as Polymorphic from '@radix-ui/react-polymorphic';
import { uniqueId } from 'lodash-es';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ProductIconName } from '../../assets/ProductIcon/ProductIcon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { FocusRing } from '../../common/focus_rings';
import { sizing } from '../../common/sizing';
import { zIndexes } from '../../common/z_indexes';
import { selectors } from '../../controls/shared/styles';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import Shortcut from '../../formatting/Shortcut/Shortcut';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { useVerticalShadowOnScroll, VerticalScrollShadow } from '../../utilities/useShadowOnScroll';
import { useViewport } from '../../utilities/useViewport';

const SidebarChildContext = createContext<boolean | undefined>(undefined);
const SidebarChildProvider = SidebarChildContext.Provider;
const useSidebarChild = (controlledValue?: boolean, defaultValue: boolean = false) => {
  const isChild = useContext(SidebarChildContext);
  return controlledValue ?? isChild ?? defaultValue;
};

const SidebarTargetChevron = styled(Icon, {
  display: 'flex',
  color: '$$iconColor',
  margin: '0 $2',

  '@notDesktop': {
    width: '$16',
    height: '$16',
  },

  '@desktop': {
    width: '$12',
    height: '$12',
  },
});

const SidebarTargetIcon = styled(Icon, {
  display: 'flex',
  color: '$$iconColor',

  '@notDesktop': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

const SidebarTargetStart = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '@notDesktop': {
    gap: '$8',
  },

  '@desktop': {
    gap: '$6',
  },
});

const SidebarTargetLabel = styled(Body, {
  width: '100%',
  fontWeight: fontWeights.bold,
  color: '$$labelColor',
  truncate: true,

  [darkThemeSelector]: {
    color: '$$labelColor',
  },
});

const SidebarTargetLink = styled('a', FocusRing, {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  $$labelColor: colors.bodyNeutralLight,
  $$iconColor: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    $$labelColor: colors.bodyNeutralDark,
    $$iconColor: colors.bodyNeutralDark,
  },

  '@notDesktop': {
    padding: '$8 $12',
    borderRadius: '$10',
  },

  '@desktop': {
    padding: '$4 $8',
    borderRadius: '$8',
  },

  [selectors.hover]: {
    zIndex: 2,
    backgroundColor: colors.bgNeutralLight,
    strokeAll: colors.strokeNeutralLight,
    $$labelColor: colors.headingNeutralLight,
    $$iconColor: colors.bodyNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.bgNeutralDark,
      strokeAll: colors.strokeNeutralDark,
      $$labelColor: colors.headingNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
    },
  },

  [selectors.focus]: {
    zIndex: 4,
  },

  variants: {
    internal: {
      true: {},
      false: {},
    },
    isChild: {
      true: {},
      false: {},
    },
    isSelected: {
      true: {
        zIndex: 3,
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,
          $$labelColor: colors.headingBrandLight,
          $$iconColor: colors.bodyBrandLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
            $$labelColor: colors.headingBrandDark,
            $$iconColor: colors.bodyBrandDark,
          },
        },
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        $$labelColor: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,

        [darkThemeSelector]: {
          $$labelColor: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
        },

        [selectors.hover]: {
          $$labelColor: colors.internalHeadingLight,
          $$iconColor: colors.internalBodyLight,

          [darkThemeSelector]: {
            $$labelColor: colors.internalHeadingDark,
            $$iconColor: colors.internalBodyDark,
          },
        },
      },
    },
    {
      internal: true,
      isSelected: true,
      css: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.internalBgLight,
          strokeAll: colors.internalStrokeLight,

          [darkThemeSelector]: {
            backgroundColor: colors.internalBgDark,
            strokeAll: colors.internalStrokeDark,
          },
        },
      },
    },
  ],
});

const SidebarTargetLI = styled('li', {
  position: 'relative',
  width: '100%',
});

export interface SidebarTargetProps {
  beta?: boolean;
  icon?: IconName;
  internal?: boolean;
  isChild?: boolean;
  isCollapsed?: boolean;
  isParent?: boolean;
  isSelected?: boolean;
  label?: React.ReactNode;
  shortcut?: React.ReactNode[];
}

export const SidebarTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      beta,
      icon,
      internal,
      isChild,
      isCollapsed,
      isParent,
      isSelected,
      label,
      shortcut,
      ...props
    }: PolymorphicComponentProps<Tag, SidebarTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => {
    const { breakpoint } = useViewport();
    const isChildContext = useSidebarChild(isChild, false);
    return (
      <SidebarTargetLI role="menu-item">
        <SidebarTargetLink
          as={as}
          ref={forwardedRef}
          aria-label={`${label}`}
          {...props}
          internal={internal}
          isChild={isChildContext}
          isSelected={isSelected}
        >
          <AlignStack
            direction="row"
            gap={breakpoint === 'desktop' ? 6 : 8}
            preset="body"
            start={
              ((!isChild && isParent) || icon) && (
                <SidebarTargetStart>
                  {!isChild && isParent && (
                    <SidebarTargetChevron icon={isCollapsed ? 'chevron-right' : 'chevron-down'} />
                  )}
                  {icon && <SidebarTargetIcon icon={icon} />}
                </SidebarTargetStart>
              )
            }
            end={
              (beta || shortcut) && (
                <>
                  {beta && <FeatureBadge type="beta" size="x-small" />}
                  {shortcut && <Shortcut keys={shortcut} />}
                </>
              )
            }
          >
            <SidebarTargetLabel>{label}</SidebarTargetLabel>
          </AlignStack>
        </SidebarTargetLink>
      </SidebarTargetLI>
    );
  },
) as Polymorphic.ForwardRefComponent<React.ElementType, SidebarTargetProps>;

const SidebarGroupChevron = styled(Icon, {
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },
});

const SidebarGroupLabel = styled(Small, {
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
});

const SidebarGroupHeader = styled('div', {
  display: 'flex',
  width: '100%',
  minHeight: '$16',

  '@notDesktop': {
    padding: '$0 0 $4 $12',
  },

  '@desktop': {
    padding: '$0 0 $4 $8',
  },
});

const SidebarGroupItemsContainer = styled('ul', {
  vStack: '$0',
  alignItems: 'stretch',
  width: '100%',
  variants: {
    collapsed: {
      true: {
        display: 'none',
      },
      false: {
        display: 'flex',
      },
    },
  },
});

type SidebarGroupItemsProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  id?: string;
};

export function SidebarGroupItems({
  children,
  collapsed,
  id,
  ...remaining
}: SidebarGroupItemsProps) {
  return (
    <SidebarGroupItemsContainer id={id} role="menu" collapsed={collapsed} {...remaining}>
      {children}
    </SidebarGroupItemsContainer>
  );
}

const SidebarGroupContainer = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '$4',

  variants: {
    isCollapsed: {
      true: {
        display: 'none',
      },
      false: {},
    },
    isChild: {
      true: {
        position: 'relative',
        padding: '$4 0',

        '@notDesktop': {
          paddingLeft: '$30',
        },

        '@desktop': {
          paddingLeft: '$22',
        },
      },
      false: {},
    },
  },
});

type SidebarGroupProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  label?: React.ReactNode;
  onClick?: (event: any) => void;
  id?: string;
  isCollapsed?: boolean;
  isChild?: boolean;
};

export function SidebarGroup({
  children,
  collapsed,
  onClick,
  label,
  id,
  isCollapsed,
  isChild,
  ...remaining
}: SidebarGroupProps) {
  const { breakpoint } = useViewport();
  const [groupID] = useState(uniqueId('sgi-'));
  return (
    <SidebarGroupContainer role="group" isCollapsed={isCollapsed} isChild={isChild} {...remaining}>
      {(onClick || label) && (
        <SidebarGroupHeader
          aria-controls={onClick && groupID}
          aria-expanded={!(onClick && collapsed)}
          role={onClick && 'button'}
          onClick={onClick}
          id={`${groupID}_${id}`}
        >
          <AlignStack
            direction="row"
            gap={breakpoint === 'desktop' ? 6 : 8}
            preset="caption"
            start={
              onClick && (
                <SidebarGroupChevron icon={collapsed ? 'chevron-right' : 'chevron-down'} size={8} />
              )
            }
          >
            {label && <SidebarGroupLabel>{label}</SidebarGroupLabel>}
          </AlignStack>
        </SidebarGroupHeader>
      )}
      <SidebarGroupItems aria-labelledby={`${groupID}_${id}`} id={groupID} collapsed={collapsed}>
        <SidebarChildProvider value={isChild}>{children}</SidebarChildProvider>
      </SidebarGroupItems>
    </SidebarGroupContainer>
  );
}

const SidebarGroupsScroll = styled('ul', VerticalScrollShadow, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  padding: sizing.contentSidesOnly,
  overflowY: 'auto',

  variants: {
    collapsible: {
      true: {
        gap: '$4',
      },
      false: {
        gap: '$16',
      },
    },
  },
});

const SidebarGroupsContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  [`&:first-child ${SidebarGroupContainer}:first-child`]: {
    paddingTop: sizing.contentEnds,
  },

  [`&:last-child ${SidebarGroupContainer}:last-child`]: {
    paddingBottom: sizing.contentEnds,
  },
});

type SidebarGroupsProps = {
  children?: React.ReactNode;
  collapsible?: boolean;
  id?: string;
};

export function SidebarGroups({ children, collapsible = false, ...remaining }: SidebarGroupsProps) {
  const sidebarScrollRef = useRef<HTMLUListElement>(null);
  const { getBoxShadow, onScrollHandler, handleTargetChange } = useVerticalShadowOnScroll('both');

  const handleResize = useCallback(() => {
    if (sidebarScrollRef.current) {
      handleTargetChange(sidebarScrollRef.current);
    }
  }, [handleTargetChange]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <SidebarGroupsContainer {...remaining}>
      <SidebarGroupsScroll
        role="menu"
        onScroll={onScrollHandler}
        ref={sidebarScrollRef}
        {...getBoxShadow}
        collapsible={collapsible}
      >
        {children}
      </SidebarGroupsScroll>
    </SidebarGroupsContainer>
  );
}

const SidebarEndsContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  width: '100%',
  padding: sizing.contentSquish,
});

const SidebarStart = styled(SidebarEndsContainer);
const SidebarEnd = styled(SidebarEndsContainer);

// Guarantees a minimum internal width for the Drawer.
const sidebarWidthTablet = 280 + sizing.primary;
const sidebarWidthDesktop = 244 + sizing.primary;

const SidebarContainer = styled('div', {
  gridArea: 'sidebar',
  position: 'relative',
  zIndex: zIndexes.sidebar,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: colors.bgApplicationLight,

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
  },

  '@mobile': {
    width: '100%',
    minWidth: '100%',
  },

  '@notMobile': {
    borderTopLeftRadius: '$8',
    boxShadow: `
      0 0 0 1px ${fade(palette.gray900, 0.04)},
      2px 0 3px 0 ${fade(palette.gray900, 0.04)},
      -2px 0 3px 0 ${fade(palette.gray900, 0.04)}
    `,

    [darkThemeSelector]: {
      boxShadow: `
        0 0 0 1px ${fade(palette.gray50, 0.08)},
        2px 0 3px 0 ${fade(palette.gray50, 0.08)},
        -2px 0 3px 0 ${fade(palette.gray50, 0.08)}
      `,
    },
  },

  '@tablet': {
    width: `$${sidebarWidthTablet}`,
    minWidth: `$${sidebarWidthTablet}`,
  },

  '@desktop': {
    width: `$${sidebarWidthDesktop}`,
    minWidth: `$${sidebarWidthDesktop}`,
  },

  variants: {
    collapsed: {
      true: {
        '@mobile': {
          display: 'none',
        },
      },
      false: {},
    },
  },
});

type SidebarProps = {
  children?: React.ReactNode;
  collapsed?: boolean;
  end?: React.ReactNode;
  heading?: React.ReactNode;
  icon?: IconName;
  product?: ProductIconName;
  start?: React.ReactNode;
};

export function Sidebar({
  children,
  collapsed,
  end,
  heading,
  icon,
  product,
  start,
  ...remaining
}: SidebarProps) {
  return (
    <SidebarContainer collapsed={collapsed} {...remaining}>
      {start && <SidebarStart>{start}</SidebarStart>}
      {children}
      {end && <SidebarEnd>{end}</SidebarEnd>}
    </SidebarContainer>
  );
}
