import type { ButtonHTMLAttributes } from 'react';
import React, { useId } from 'react';

import type { DropdownMenuItemProps } from '../../components/DropdownMenu/DropdownMenu';
import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
} from '../../components/DropdownMenu/DropdownMenu';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { Button } from '../Button/Button';
import { ControlGroup } from '../ControlGroup/ControlGroup';
import { selectors } from '../shared/styles';

const BreadcrumbHomeIcon = styled(Icon, {
  width: '$14',
  height: '$14',
  color: '$$iconColor',
});

const BreadcrumbLabel = styled(Small, {
  color: '$$labelColor',
  fontWeight: fontWeights.extraBold,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const BreadcrumbPageContainer = styled('span', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '$4 $6',
  minWidth: 0,
  borderRadius: '$8',
  $$labelColor: colors.bodyNeutralLight,
  $$iconColor: colors.iconNeutralLight,

  [darkThemeSelector]: {
    $$labelColor: colors.bodyNeutralDark,
    $$iconColor: colors.iconNeutralDark,
  },

  [selectors.hover]: {
    $$labelColor: colors.headingNeutralLight,
    $$iconColor: colors.bodyNeutralLight,

    [darkThemeSelector]: {
      $$labelColor: colors.headingNeutralDark,
      $$iconColor: colors.bodyNeutralDark,
    },
  },

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    hasLink: {
      true: {
        cursor: 'pointer',

        [selectors.focus]: {
          zIndex: 3,
          boxShadow: shadows.focusRingLight,

          [darkThemeSelector]: {
            boxShadow: shadows.focusRingDark,
          },
        },
      },
      false: {
        cursor: 'default',
      },
    },
    onClick: {
      true: {},
    },
    selected: {
      true: {
        zIndex: 2,
        $$labelColor: colors.bodyBrandLight,
        $$iconColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          $$labelColor: colors.bodyBrandDark,
          $$iconColor: colors.iconBrandDark,
        },
      },
      false: {
        zIndex: 1,
      },
    },
  },
});

const BreadcrumbFieldContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  minWidth: 0,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 $4',
});

const BreadcrumbStepper = styled(Icon, {
  width: '$8',
  height: '$8',
  color: colors.iconNeutralLight,

  [darkThemeSelector]: {
    color: colors.iconNeutralDark,
  },

  '&:last-child': {
    display: 'none',
  },
});
export interface BreadcrumbPageProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  as?: any;
  to?: any;
  /**
   * The label of the breadcrumb page.
   */
  label: React.ReactNode;
  /**
   * Whether the breadcrumb page is selected.
   */
  selected?: boolean;
}

export function BreadcrumbPage<Tag extends React.ElementType>({
  as,
  to,
  label,
  selected,
  ref,
  ...remaining
}: PolymorphicComponentProps<Tag, BreadcrumbPageProps>) {
  return (
    <BreadcrumbPageContainer
      as={as}
      to={to}
      hasLink={!!to}
      tabIndex={to ? 0 : undefined}
      ref={ref}
      role="tab"
      aria-selected={selected ? 'true' : 'false'}
      selected={selected}
      {...(remaining as object)}
    >
      <BreadcrumbLabel>{label}</BreadcrumbLabel>
    </BreadcrumbPageContainer>
  );
}

export interface BreadcrumbFieldProps {
  field: React.ReactNode;
}

function BreadcrumbField({ field }: BreadcrumbFieldProps) {
  return <BreadcrumbFieldContainer>{field}</BreadcrumbFieldContainer>;
}
export interface BreadcrumbProps {
  field?: BreadcrumbFieldProps;
  page?: BreadcrumbPageProps;
}

export function Breadcrumb({ field, page }: BreadcrumbProps) {
  if (field) {
    return <BreadcrumbField {...field} />;
  }
  if (page) {
    return <BreadcrumbPage {...page} />;
  }
  return null;
}

const BreadcrumbsCrumbs = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const BreadcrumbsContainer = styled('nav', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
});

export type BreadcrumbsPropBack = React.MouseEventHandler<HTMLButtonElement>;
export type BreadcrumbsPropHome = Omit<BreadcrumbPageProps, 'label'>;
export type BreadcrumbsPropHistory = DropdownMenuItemProps;

export type BreadcrumbsProps = {
  back?: BreadcrumbsPropBack;
  crumbs?: BreadcrumbProps[];
  home?: BreadcrumbsPropHome;
  history?: BreadcrumbsPropHistory[];
};

export function Breadcrumbs({ back, crumbs, home, history, ...remaining }: BreadcrumbsProps) {
  const uuid = useId();
  return (
    <BreadcrumbsContainer aria-label="Breadcrumbs" role="tablist" {...remaining}>
      {(history || back) && (
        <ControlGroup relation="joined" size="small" variant="secondary">
          {history && history.length > 0 && (
            <DropdownMenu>
              <DropdownMenuButton arrangement="hidden-label" icon="clock">
                History
              </DropdownMenuButton>
              <DropdownMenuPopover>
                <DropdownMenuGroup label="History">
                  {history.map((historyItem) => (
                    <DropdownMenuItem {...historyItem} />
                  ))}
                </DropdownMenuGroup>
              </DropdownMenuPopover>
            </DropdownMenu>
          )}
          {back && (
            <Button arrangement="hidden-label" icon="chevron-left" onClick={back}>
              Back
            </Button>
          )}
        </ControlGroup>
      )}
      {(home || crumbs) && (
        <BreadcrumbsCrumbs>
          {home && (
            <>
              <BreadcrumbPage {...home} label={<BreadcrumbHomeIcon icon="home" />} />
              <BreadcrumbStepper icon="chevron-right" />
            </>
          )}
          {crumbs &&
            crumbs.map((crumb, index) => (
              <React.Fragment
                key={`breadcrumb-${crumb.field ? 'field' : crumb.page?.label}-${uuid}`}
              >
                <Breadcrumb {...crumb} />
                {index !== crumbs.length - 1 && <BreadcrumbStepper icon="chevron-right" />}
              </React.Fragment>
            ))}
        </BreadcrumbsCrumbs>
      )}
    </BreadcrumbsContainer>
  );
}
