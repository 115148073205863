import React, { createContext, useContext } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import type { ControlSize } from '../shared/types';
import { Icon } from '../../assets/Icon/Icon';
import { ControlSizeProvider, useControlSize } from '../../common/control_size';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { LargeSansSizes } from '../../text/Large';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { selectors } from '../shared/styles';

const SegmentsDirectionContext = createContext<SegmentsDirection | undefined>(undefined);
export const SegmentsDirectionProvider = SegmentsDirectionContext.Provider;
export const useSegmentsDirection = (
  controlledValue?: SegmentsDirection,
  defaultValue: SegmentsDirection = 'row',
) => {
  const segmentsDirection = useContext(SegmentsDirectionContext);
  return controlledValue ?? segmentsDirection ?? defaultValue;
};

export const SegmentIcon = styled(Icon, {
  color: colors.bodyNeutralLight,

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  variants: {
    active: {
      true: {
        color: colors.bodyBrandLight,
        [darkThemeSelector]: {
          color: colors.bodyBrandDark,
        },
      },
      false: {},
    },
    direction: {
      column: {},
      row: {},
    },
    size: {
      'x-small': {},
      small: {},
      medium: {},
      large: {},
      'x-large': {},
    },
    variant: {
      destructive: {
        color: colors.iconNegativeLight,

        [darkThemeSelector]: {
          color: colors.iconNegativeDark,
        },
      },
      primary: {},
    },
  },
  compoundVariants: [
    {
      active: true,
      variant: 'destructive',
      css: {
        color: colors.iconNegativeLight,

        [darkThemeSelector]: {
          color: colors.iconNegativeDark,
        },
      },
    },
    {
      direction: 'column',
      size: 'x-small',
      css: {
        width: '$16',
        height: '$16',
      },
    },
    {
      direction: 'column',
      size: 'small',
      css: {
        width: '$20',
        height: '$20',
      },
    },
    {
      direction: 'column',
      size: 'medium',
      css: {
        width: '$28',
        height: '$28',
      },
    },
    {
      direction: 'column',
      size: 'large',
      css: {
        width: '$32',
        height: '$32',
      },
    },
    {
      direction: 'column',
      size: 'x-large',
      css: {
        width: '$40',
        height: '$40',
      },
    },
    {
      direction: 'row',
      size: 'x-small',
      css: {
        width: '$12',
        height: '$12',
      },
    },
    {
      direction: 'row',
      size: 'small',
      css: {
        width: '$12',
        height: '$12',
      },
    },
    {
      direction: 'row',
      size: 'medium',
      css: {
        width: '$12',
        height: '$12',
      },
    },
    {
      direction: 'row',
      size: 'large',
      css: {
        width: '$16',
        height: '$16',
      },
    },
    {
      direction: 'row',
      size: 'x-large',
      css: {
        width: '$16',
        height: '$16',
      },
    },
  ],
});

export const SegmentLabel = styled(Text, {
  color: colors.bodyNeutralLight,
  fontWeight: fontWeights.bold,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  variants: {
    active: {
      true: {
        color: colors.headingBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
        },
      },
      false: {},
    },
    size: {
      'x-small': {
        ...SmallSansSizes,
      },
      small: {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
      large: {
        ...BodySansSizes,
      },
      'x-large': {
        ...LargeSansSizes,
      },
    },
    variant: {
      destructive: {
        color: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
        },
      },
      primary: {},
    },
  },
  compoundVariants: [
    {
      active: true,
      variant: 'destructive',
      css: {
        color: colors.bodyNegativeLight,

        [darkThemeSelector]: {
          color: colors.bodyNegativeDark,
        },
      },
    },
  ],
});

const segmentDivider = {
  content: '',
  position: 'absolute',
  zIndex: 1,
  top: '4px',
  right: '-0.5px',
  bottom: '4px',
  display: 'block',
  width: '1px',
  backgroundColor: colors.strokeNeutralLight,
  borderRadius: '$8',
  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },
};

const SegmentContainer = styled('button', {
  position: 'relative',
  display: 'flex',
  minWidth: 0,
  borderRadius: '$8',
  justifyContent: 'center',
  alignItems: 'center',

  [selectors.focus]: {
    zIndex: 3,
    outline: 'none',
    boxShadow: shadows.focusRingLight,
    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  '&:after': {
    ...segmentDivider,
  },

  '&:last-child:after': {
    display: 'none',
  },

  variants: {
    active: {
      true: {
        zIndex: 2,

        '&:after': {
          display: 'none',
        },
      },
      false: {
        zIndex: 1,
      },
    },
    arrangement: {
      'hidden-label': {},
      'leading-icon': {},
      'leading-label': {},
    },
    direction: {
      column: {},
      row: {},
    },
    size: {
      'x-small': {
        '&:after': {
          top: '$4',
          bottom: '$4',
        },
      },
      small: {
        '&:after': {
          top: '$6',
          bottom: '$6',
        },
      },
      medium: {
        '&:after': {
          top: '$8',
          bottom: '$8',
        },
      },
      large: {
        '&:after': {
          top: '$10',
          bottom: '$10',
        },
      },
      'x-large': {
        '&:after': {
          top: '$12',
          bottom: '$12',
        },
      },
    },
    variant: {
      destructive: {},
      primary: {},
    },
  },
  compoundVariants: [
    {
      direction: 'column',
      arrangement: 'leading-icon',
      css: {
        flexDirection: 'column',
      },
    },
    {
      direction: 'column',
      arrangement: 'leading-label',
      css: {
        flexDirection: 'column-reverse',
      },
    },
    {
      direction: 'row',
      arrangement: 'leading-icon',
      css: {
        flexDirection: 'row',
      },
    },
    {
      direction: 'row',
      arrangement: 'leading-label',
      css: {
        flexDirection: 'row-reverse',
      },
    },
    {
      direction: 'row',
      size: 'small',
      css: {
        minWidth: '$28',
        minHeight: '$24',
        padding: '$4 $6',
      },
    },
    {
      direction: 'row',
      size: 'medium',
      css: {
        minWidth: '$32',
        minHeight: '$28',
        padding: '$4 $8',
      },
    },
    {
      direction: 'row',
      size: 'large',
      css: {
        minWidth: '$40',
        minHeight: '$36',
        padding: '$8 $12',
      },
    },
    {
      direction: 'row',
      size: 'x-large',
      css: {
        minWidth: '$44',
        minHeight: '$40',
        padding: '$8 $12',
      },
    },
    {
      direction: 'column',
      size: 'small',
      css: {
        padding: '$6 $8 $4',
      },
    },
    {
      direction: 'column',
      size: 'medium',
      css: {
        padding: '$8 $12',
      },
    },
    {
      direction: 'column',
      size: 'large',
      css: {
        padding: '$12 $16',
      },
    },
    {
      direction: 'column',
      size: 'x-large',
      css: {
        padding: '$12 $16',
      },
    },
    {
      active: true,
      variant: 'primary',
      css: {
        background: colors.bgBrandLight,
        strokeAll: colors.strokeBrandLight,

        [darkThemeSelector]: {
          backgroundColor: colors.bgBrandDark,
          strokeAll: colors.strokeBrandDark,
        },

        [selectors.hover]: {
          [`${SegmentIcon}`]: {
            color: colors.bodyBrandLight,

            [darkThemeSelector]: {
              color: colors.bodyBrandDark,
            },
          },
          [`${SegmentLabel}`]: {
            color: colors.headingBrandLight,

            [darkThemeSelector]: {
              color: colors.headingBrandDark,
            },
          },
        },
      },
    },
    {
      active: false,
      variant: 'primary',
      css: {
        [selectors.hover]: {
          [`${SegmentIcon}`]: {
            color: colors.bodyNeutralLight,
            [darkThemeSelector]: {
              color: colors.bodyNeutralDark,
            },
          },
          [`${SegmentLabel}`]: {
            color: colors.headingNeutralLight,
            [darkThemeSelector]: {
              color: colors.headingNeutralDark,
            },
          },
        },
      },
    },
    {
      active: true,
      variant: 'destructive',
      css: {
        background: colors.bgNegativeLight,
        strokeAll: colors.strokeNegativeLight,

        [darkThemeSelector]: {
          backgroundColor: colors.bgNegativeDark,
          strokeAll: colors.strokeNegativeDark,
        },

        [selectors.hover]: {
          [`${SegmentIcon}`]: {
            color: colors.bodyNegativeLight,

            [darkThemeSelector]: {
              color: colors.bodyNegativeDark,
            },
          },
          [`${SegmentLabel}`]: {
            color: colors.headingNegativeLight,

            [darkThemeSelector]: {
              color: colors.headingNegativeDark,
            },
          },
        },
      },
    },
    {
      active: false,
      variant: 'destructive',
      css: {
        [selectors.hover]: {
          [`${SegmentIcon}`]: {
            color: colors.bodyNegativeLight,
            [darkThemeSelector]: {
              color: colors.bodyNegativeDark,
            },
          },
          [`${SegmentLabel}`]: {
            color: colors.headingNegativeLight,
            [darkThemeSelector]: {
              color: colors.headingNegativeDark,
            },
          },
        },
      },
    },
  ],
});

type SegmentArrangement = 'hidden-label' | 'leading-icon' | 'leading-label';
type SegmentDirection = 'column' | 'row';
type SegmentSize = ControlSize;
export interface SegmentProps {
  active?: boolean;
  arrangement?: SegmentArrangement;
  children: React.ReactNode;
  direction?: SegmentDirection;
  icon?: IconName;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: SegmentSize;
  variant?: 'destructive' | 'primary';
}

const getSegmentDirection = (arrangement: SegmentArrangement, direction: SegmentDirection) => {
  if (arrangement === 'leading-icon' && direction === 'column') {
    return 'column';
  }
  if (arrangement === 'leading-label' && direction === 'column') {
    return 'column-reverse';
  }
  if (arrangement === 'leading-label' && direction === 'row') {
    return 'row-reverse';
  }
  return 'row';
};

const getSegmentGap = (direction: SegmentDirection, size: SegmentSize) => {
  if (direction === 'column') {
    switch (size) {
      case 'x-small':
      case 'small':
        return 2;
    }
  }
  return 4;
};

const getSegmentPreset = (size: SegmentSize) => {
  switch (size) {
    case 'x-small':
    case 'small':
      return 'small';
    case 'x-large':
      return 'large';
  }
  return 'body';
};

export function Segment<Tag extends React.ElementType>({
  active = false,
  arrangement = 'leading-icon',
  children,
  direction,
  icon,
  size,
  variant = 'primary',
  ref,
  ...remaining
}: PolymorphicComponentProps<Tag, SegmentProps>) {
  const controlSize = useControlSize(size, 'medium');
  const segmentsDirection = useSegmentsDirection(direction, 'row');
  return (
    <SegmentContainer
      type="button"
      role="tab"
      aria-selected={active ? 'true' : 'false'}
      active={active}
      arrangement={arrangement}
      direction={segmentsDirection}
      size={controlSize}
      variant={variant}
      {...remaining}
      ref={ref}
    >
      <AlignStack
        aria-label={arrangement === 'hidden-label' && children}
        direction={getSegmentDirection(arrangement, segmentsDirection)}
        gap={getSegmentGap(segmentsDirection, controlSize)}
        width="fit-content"
        preset={getSegmentPreset(controlSize)}
        start={
          icon && (
            <SegmentIcon
              icon={icon}
              active={active}
              direction={segmentsDirection}
              size={controlSize}
              variant={variant}
            />
          )
        }
      >
        {arrangement !== 'hidden-label' && children && (
          <SegmentLabel active={active} size={controlSize} variant={variant}>
            {children}
          </SegmentLabel>
        )}
      </AlignStack>
    </SegmentContainer>
  );
}

const SegmentsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  background: colors.bgApplicationLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$8',

  [darkThemeSelector]: {
    background: colors.bgApplicationDark,
    strokeAll: colors.strokeNeutralDark,
  },

  variants: {
    contentMode: {
      fit: {
        width: 'fit-content',
      },
      stretch: {
        width: '100%',
      },
    },
    proportions: {
      equal: {
        [`& > ${SegmentContainer}`]: {
          flex: 1,
        },
      },
      fit: {},
    },
  },
});

type SegmentsDirection = SegmentDirection;

export type SegmentsProps = {
  children: React.ReactNode;
  contentMode?: 'fit' | 'stretch';
  direction?: SegmentsDirection;
  proportions?: 'equal' | 'fit';
  size?: SegmentSize;
};

export function Segments({
  children,
  contentMode = 'stretch',
  direction = 'row',
  proportions = 'equal',
  size = 'medium',
  ...remaining
}: SegmentsProps) {
  return (
    <SegmentsContainer
      role="tablist"
      contentMode={contentMode}
      proportions={proportions}
      {...remaining}
    >
      <SegmentsDirectionProvider value={direction}>
        <ControlSizeProvider value={size}>{children}</ControlSizeProvider>
      </SegmentsDirectionProvider>
    </SegmentsContainer>
  );
}
