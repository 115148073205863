/* eslint-disable react/button-has-type,react/prop-types */
import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ShortcutKeys } from '../../formatting/Shortcut/Shortcut';
import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import type { ControlSize, ControlVariant } from '../shared/types';
import { Icon } from '../../assets/Icon/Icon';
import { LoadingIcon } from '../../assets/LoadingIcon/LoadingIcon';
import { useControlSize } from '../../common/control_size';
import { useControlVariant } from '../../common/control_variant';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import Shortcut from '../../formatting/Shortcut/Shortcut';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { BodySansSizes } from '../../text/Body';
import { LargeSansSizes } from '../../text/Large';
import { SmallSansSizes } from '../../text/Small';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import {
  AccessLevel,
  isAccessLevelVisible,
  useAccessLevelContext,
} from '../../utilities/useAccessLevelContext';
import { useViewport } from '../../utilities/useViewport';
import { selectors, transitions } from '../shared/styles';
import {
  destructiveMutedDisabledStyles,
  destructiveMutedEnabledStyles,
  destructiveVividDisabledStyles,
  destructiveVividEnabledStyles,
  iconColor,
  internalDisabledStyles,
  internalEnabledStyles,
  labelColor,
  loadingIconColor,
  primaryMutedDisabledStyles,
  primaryMutedEnabledStyles,
  primaryVividDisabledStyles,
  primaryVividEnabledStyles,
  secondaryDisabledStyles,
  secondaryEnabledStyles,
} from './ButtonStyles';

const ButtonIcon = styled(Icon, {
  opacity: 1,
  width: '$$iconSize',
  height: '$$iconSize',
  color: iconColor,
  transition: transitions.control,

  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
      false: {},
    },
  },
});

const ButtonLoadingIcon = styled(LoadingIcon, {
  color: loadingIconColor,
  transition: transitions.control,
});

const ButtonLabel = styled('span', {
  display: 'flex',
  fontWeight: fontWeights.bold,
  transition: transitions.control,
  // NOTE: Override base Text colors
  color: `${labelColor} !important`,

  [darkThemeSelector]: {
    color: `${labelColor} !important`,
  },

  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
      false: {
        opacity: 1,
      },
    },
    arrangement: {
      'leading-icon': {},
      'leading-label': {},
    },
    condense: {
      true: {},
      false: {},
    },
    size: {
      'x-small': {
        ...SmallSansSizes,
      },
      small: {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
      large: {
        ...LargeSansSizes,
      },
      'x-large': {
        ...LargeSansSizes,
      },
    },
  },
});

export const ButtonContainer = styled('button', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$$buttonGap',
  minWidth: 'min-content',
  paddingX: '$$paddingX',
  paddingY: '$$paddingY',
  opacity: 1,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  userSelect: 'none',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    appearance: {
      muted: {},
      vivid: {},
    },
    arrangement: {
      'hidden-label': {},
      'leading-icon': {},
      'leading-label': {},
    },
    size: {
      'x-small': {
        $$buttonGap: '$space$2',
        minHeight: '$20',
        $$paddingX: '$space$4',
        $$paddingY: '$space$2',
        $$iconSize: '$space$12',
        borderRadius: '$6',
      },
      small: {
        $$buttonGap: '$space$4',
        minHeight: '$24',
        $$paddingX: '$space$6',
        $$paddingY: '$space$2',
        $$iconSize: '$space$12',
        borderRadius: '$8',
      },
      medium: {
        $$buttonGap: '$space$4',
        minHeight: '$28',
        $$paddingX: '$space$8',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
        borderRadius: '$8',
      },
      large: {
        $$buttonGap: '$space$6',
        minHeight: '$36',
        $$paddingX: '$space$12',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
        borderRadius: '$10',
      },
      'x-large': {
        $$buttonGap: '$space$8',
        minHeight: '$44',
        $$paddingX: '$space$20',
        $$paddingY: '$space$6',
        $$iconSize: '$space$12',
        borderRadius: '$12',
      },
    },
    internal: {
      true: {},
      false: {},
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      false: {},
    },
    ghost: {
      true: {},
      false: {},
    },
    invalid: {
      true: {},
      false: {},
    },
    variant: {
      destructive: {},
      primary: {},
      secondary: {},
    },
  },
  compoundVariants: [
    // Icon only
    {
      arrangement: 'hidden-label',
      size: 'x-small',
      css: {
        minWidth: '$24',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'small',
      css: {
        minWidth: '$28',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'medium',
      css: {
        minWidth: '$32',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'large',
      css: {
        minWidth: '$40',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'x-large',
      css: {
        minWidth: '$48',
      },
    },
    // Destructive
    {
      appearance: 'vivid',
      variant: 'destructive',
      isDisabled: false,
      css: {
        '&[type=button], &[type=submit]': destructiveVividEnabledStyles,
        '&': destructiveVividEnabledStyles,
      },
    },
    {
      appearance: 'vivid',
      variant: 'destructive',
      isDisabled: true,
      css: destructiveVividDisabledStyles,
    },

    {
      appearance: 'muted',
      variant: 'destructive',
      isDisabled: false,
      css: destructiveMutedEnabledStyles,
    },

    {
      appearance: 'muted',
      variant: 'destructive',
      isDisabled: true,
      css: destructiveMutedDisabledStyles,
    },

    // Primary
    {
      appearance: 'vivid',
      variant: 'primary',
      isDisabled: false,
      css: {
        '&[type=button], &[type=submit]': primaryVividEnabledStyles,
        '&': primaryVividEnabledStyles,
      },
    },
    {
      appearance: 'vivid',
      variant: 'primary',
      isDisabled: true,
      css: primaryVividDisabledStyles,
    },

    {
      appearance: 'muted',
      variant: 'primary',
      isDisabled: false,
      css: primaryMutedEnabledStyles,
    },
    {
      appearance: 'muted',
      variant: 'primary',
      isDisabled: true,
      css: primaryMutedDisabledStyles,
    },

    // Secondary
    {
      variant: 'secondary',
      isDisabled: false,
      css: secondaryEnabledStyles,
    },
    {
      variant: 'secondary',
      isDisabled: true,
      css: secondaryDisabledStyles,
    },

    // Internal
    {
      internal: true,
      isDisabled: false,
      css: internalEnabledStyles,
    },
    {
      internal: true,
      isDisabled: true,
      css: internalDisabledStyles,
    },

    {
      isDisabled: false,
      invalid: true,
      css: {
        boxShadow: shadows.fieldErrorLight,

        '&:hover:not(:focus)': {
          boxShadow: shadows.fieldErrorLight,
        },

        [darkThemeSelector]: {
          boxShadow: shadows.fieldErrorDark,

          '&:hover:not(:focus)': {
            boxShadow: shadows.fieldErrorDark,
          },
        },
      },
    },

    // Ghost
    {
      ghost: true,
      css: {
        '&[data-ghost="true"]:not(:hover, :focus, :focus-visible, :focus-within)': {
          backgroundColor: colors.transparent,
          boxShadow: colors.transparent,
        },
      },
    },
  ],
});

const ButtonLoadingContainer = styled('div', {
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonRow = styled(AlignStack, {
  gap: '$$buttonGap',
  width: 'fit-content',
});

const ButtonMenuArrow = styled(ButtonIcon, {
  height: '$10',
  width: '$10',
});

export type ButtonAppearanceProp = 'muted' | 'vivid';
export type ButtonArrangementProp = 'hidden-label' | 'leading-icon' | 'leading-label';
export type ButtonMenuArrowProp = 'dropdown' | 'select';
export type ButtonLabelAlignProp = 'start' | 'center' | 'end';
export type ButtonSizeProp = ControlSize;
export type ButtonTypeProp = 'button' | 'submit' | 'reset';
export type ButtonVariantProp = ControlVariant;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * The level required to access the button.
   */
  accessLevel?: AccessLevel;
  /**
   * Determines if the button is muted (more flat, simple colors) or vivid (more pronounced, colorful).
   */
  appearance?: ButtonAppearanceProp;
  /**
   * Determines the layout of the button's content.
   */
  arrangement?: ButtonArrangementProp;
  /**
   * The content of the button.
   */
  children: React.ReactNode;
  /**
   * Hides the button's label for smaller viewports.
   */
  condense?: boolean;
  /**
   * Disables the button.
   */
  disabled?: boolean;
  /**
   * Marks the button as invalid.
   */
  invalid?: boolean;
  /**
   * Set the form attribute.
   */
  form?: string;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Marks the button as internal.
   */
  internal?: boolean;
  /**
   * Hides the button's background and stroke until interacted with.
   */
  ghost?: boolean;
  /**
   * Displays a dropdown arrow for menus.
   */
  menuArrow?: ButtonMenuArrowProp;
  /**
   * Set the alignment of the button's label.
   */
  labelAlign?: ButtonLabelAlignProp;
  /**
   * Displays a loading icon.
   */
  loading?: boolean;
  /**
   * Set shortcut keys for the button.
   */
  shortcut?: ShortcutKeys[];
  /**
   * Set the size of the button.
   */
  size?: ButtonSizeProp;
  /**
   * Set the type of the button.
   */
  type?: ButtonTypeProp;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: ButtonVariantProp;
  /**
   * Set a width.
   */
  width?: string;
  /**
   * Set a maximum width.
   */
  maxWidth?: string;
  /**
   * Set a minimum width.
   */
  minWidth?: string;
}

const getButtonPreset = (size: ButtonSizeProp) => {
  if (size === 'x-small' || size === 'small') return 'small';
  if (size === 'x-large' || size === 'large') return 'large';
  return 'body';
};

export function Button<Tag extends React.ElementType>({
  appearance = 'vivid',
  'aria-label': ariaLabel,
  arrangement = 'leading-label',
  children,
  condense,
  disabled = false,
  ghost,
  icon,
  accessLevel: accessLevelProp,
  internal,
  invalid = false,
  labelAlign,
  loading = false,
  maxWidth,
  menuArrow,
  minWidth,
  shortcut,
  size,
  type = 'button',
  variant,
  width,
  ref,
  ...rest
}: PolymorphicComponentProps<Tag, ButtonProps>) {
  const { breakpoint } = useViewport();
  const controlSize = useControlSize(size, 'medium');
  const controlVariant = useControlVariant(variant, 'primary');
  const accessLevel = useAccessLevelContext(accessLevelProp);

  // Disable the button during its loading state
  const isDisabled = disabled || loading;

  const hiddenLabel = (condense && breakpoint !== 'desktop') || arrangement === 'hidden-label';

  const buttonRender = (
    <ButtonContainer
      {...(rest as object)}
      type={type}
      ref={ref}
      aria-label={
        ariaLabel || (arrangement === 'hidden-label' || condense ? `${children}` : undefined)
      }
      data-ghost={ghost}
      appearance={ghost ? 'muted' : appearance}
      arrangement={arrangement}
      variant={controlVariant}
      size={controlSize}
      internal={internal || accessLevel === AccessLevel.Internal}
      ghost={ghost}
      isDisabled={isDisabled}
      disabled={isDisabled}
      invalid={invalid}
      style={{
        width,
        maxWidth,
        minWidth,
      }}
    >
      {loading && (
        <ButtonLoadingContainer>
          <ButtonLoadingIcon />
        </ButtonLoadingContainer>
      )}
      <ButtonRow
        direction="row"
        justify="center"
        preset={getButtonPreset(controlSize)}
        end={
          (shortcut || menuArrow) && (
            <>
              {shortcut && (
                <Shortcut
                  control={appearance !== 'muted'}
                  internal={internal}
                  variant={controlVariant === 'secondary' ? 'neutral' : controlVariant}
                  keys={shortcut}
                />
              )}
              {menuArrow && (
                <ButtonMenuArrow
                  isLoading={loading}
                  icon={menuArrow === 'dropdown' ? 'chevron-down' : 'chevrons-vertical'}
                />
              )}
            </>
          )
        }
      >
        <ButtonRow
          direction={
            arrangement === 'leading-icon' || arrangement === 'hidden-label' ? 'row' : 'row-reverse'
          }
          justify="center"
          preset={getButtonPreset(controlSize)}
          start={icon && <ButtonIcon isLoading={loading} icon={icon} />}
          end={
            accessLevel &&
            isAccessLevelVisible(accessLevel) && (
              <FeatureBadge size="x-small" arrangement="icon-only" type={accessLevel} />
            )
          }
        >
          {!hiddenLabel && (
            <ButtonLabel isLoading={loading} size={controlSize}>
              {children}
            </ButtonLabel>
          )}
        </ButtonRow>
      </ButtonRow>
    </ButtonContainer>
  );

  if (arrangement === 'hidden-label' || (condense && breakpoint !== 'desktop')) {
    return (
      <Tooltip contents={children} sideOffset={2}>
        {buttonRender}
      </Tooltip>
    );
  }

  return buttonRender;
}
