import type { AriaTagGroupProps } from 'react-aria';
import type { Key, ListProps, ListState } from 'react-stately';
import * as React from 'react';
import { useFilter } from 'react-aria';
import { useListState } from 'react-stately';

import { Button } from '../../controls/Button/Button';
import { styled } from '../../stitches.config';
import { VStack } from '../../utilities/Stack/VStack';
import { BaseInput } from '../BaseInput/BaseInput';
import { ComboBoxList } from '../ComboBox/ComboBoxList';
import { useFilteredList } from './useFilteredList';

export { Item, Section } from 'react-stately';

const MultiComboBoxOverlayContainer = styled('div', {
  padding: '$2',
});

// Handles focusing the previous item in the list and skips over sections.
function focusPrevItem(state: ListState<any>, key: string | number | null) {
  if (!key) {
    return;
  }

  const prevKey = state.collection.getKeyBefore(key);

  if (!prevKey) {
    return;
  }

  const prevNode = state.collection.getItem(prevKey);

  if (!prevNode) {
    return;
  }

  if (prevNode && prevNode.type === 'section') {
    focusPrevItem(state, prevKey);
    return;
  }

  state.selectionManager.setFocusedKey(prevNode.key);
}

// Handles focusing the next item in the list and skips over sections.
function focusNextItem(state: ListState<any>, key: Key | null) {
  if (!key) {
    return;
  }

  const nextKey = state.collection.getKeyAfter(key);

  if (!nextKey) {
    return;
  }

  const nextNode = state.collection.getItem(nextKey);

  if (!nextNode) {
    return;
  }

  if (nextNode && nextNode.type === 'section') {
    focusNextItem(state, nextKey);
    return;
  }

  state.selectionManager.setFocusedKey(nextNode.key);
}

type MultiComboBoxOverlayProps<T> = ListProps<T> & {
  onClose: () => void;
  isOpen?: boolean;
  enableSelectAll?: boolean;
} & Pick<AriaTagGroupProps<T>, 'label' | 'aria-label'>;

export function MultiComboBoxOverlay<T extends object>(props: MultiComboBoxOverlayProps<T>) {
  const [inputValue, setInputValue] = React.useState('');

  const listBoxRef = React.useRef<HTMLUListElement>(null);
  const popoverRef = React.useRef(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  // TODO: use fuzzy filter from AutoTable
  const { contains } = useFilter({ sensitivity: 'base' });
  const collection = useFilteredList(props, inputValue, contains);

  const state = useListState({
    ...props,
    collection,
    selectionBehavior: 'toggle',
    selectionMode: 'multiple',
  });

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (state.selectionManager.focusedKey) {
        state.selectionManager.select(state.selectionManager.focusedKey);
      }

      return;
    }

    if (e.key === 'Escape') {
      props.onClose();
      return;
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      focusNextItem(state, state.selectionManager.focusedKey);
      return;
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault();
      focusPrevItem(state, state.selectionManager.focusedKey);
      // eslint-disable-next-line no-useless-return
      return;
    }
  }

  // autofocus the input when the popover opens.
  // this is "safer" than using the `autoFocus` prop on the input. sometimes the input focuses
  // before the popover is fully open & measured, which causes the popover to immediately close again.
  React.useEffect(() => {
    if (props.isOpen) {
      inputRef?.current?.focus();
    }
  }, [props.isOpen]);

  return (
    <div ref={popoverRef}>
      <VStack spacing={6}>
        <MultiComboBoxOverlayContainer>
          <BaseInput
            inputProps={{
              onKeyDown: onInputKeyDown,
              value: inputValue,
              onChange: (e) => {
                setInputValue(e.target.value);
              },
            }}
            icon="search"
            inputRef={inputRef}
            shouldApplyFocusStyles={false}
            suffix={
              props.enableSelectAll ? (
                <Button
                  variant="secondary"
                  onClick={() => {
                    state.selectionManager.toggleSelectAll();
                  }}
                >
                  {state.selectionManager.isSelectAll ? 'Deselect all' : 'Select all'}
                </Button>
              ) : undefined
            }
          />
        </MultiComboBoxOverlayContainer>
        <ComboBoxList
          shouldUseVirtualFocus
          shouldFocusOnHover
          shouldAlwaysFocusFirstItem
          listBoxRef={listBoxRef}
          noResultsMessage={`No results matching "${inputValue}"`}
          state={state}
          label={props.label}
          aria-label={props['aria-label']}
        />
      </VStack>
    </div>
  );
}
