import { createContext, useContext } from 'react';

export enum AccessLevel {
  Internal = 'internal',
  Alpha = 'alpha',
  Beta = 'beta',
  Public = 'public',
}

// It should be possible to compute these string variants but TypeScript is making it hard
export type AccessLevelProp = AccessLevel | 'internal' | 'alpha' | 'beta' | 'public';

export function isAccessLevelVisible(accessLevel: AccessLevelProp) {
  return accessLevel !== AccessLevel.Public;
}

// eslint-disable-next-line consistent-return
export function accessLevelPriority(accessLevel: AccessLevel): number {
  switch (accessLevel) {
    case AccessLevel.Internal:
      return 0;
    case AccessLevel.Alpha:
      return 1;
    case AccessLevel.Beta:
      return 2;
    case AccessLevel.Public:
      return 3;
  }
}

const UserAccessLevelContext = createContext<AccessLevelProp | undefined>(undefined);

export const UserAccessLevelContextProvider = UserAccessLevelContext.Provider;

export function useAccessLevelContext(controlledValue?: AccessLevel) {
  const contextValue = useContext(UserAccessLevelContext);
  return controlledValue ?? contextValue;
}
