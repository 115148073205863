import React, { useId } from 'react';

import type { DynamicIconName } from '../../assets/DynamicIcon/DynamicIcon';
import type { PortProps } from '../../hardware/Port/Port';
import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import { DynamicIcon } from '../../assets/DynamicIcon/DynamicIcon';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { selectors } from '../../common/selectors';
import { Badge } from '../../components/Badge/Badge';
import { Port } from '../../hardware/Port/Port';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { space } from '../../utilities/shared/sizes';

const HOPS_ROW_SIZE = 40;
const HOPS_COLUMN_SIZE = 32;

const HopConnectionDash = styled('div', {
  background: colors.hardwareStrokeNeutralLight,
  borderRadius: '99em',

  [darkThemeSelector]: {
    background: colors.hardwareStrokeNeutralDark,
  },

  variants: {
    layout: {
      row: {
        width: '$6',
        minWidth: '$6',
        height: '$2',
        minHeight: '$2',
      },
      column: {
        width: '$2',
        minWidth: '$2',
        height: '$6',
        minHeight: '$6',
      },
    },
    order: {
      1: {
        order: 1,
      },
      2: {
        order: 2,
      },
    },
    status: {
      connected: {},
      disconnected: {
        background: colors.hardwareStrokeNeutralLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeNeutralDark,
        },
      },
      error: {
        background: colors.hardwareStrokeNegativeLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeNegativeDark,
        },
      },
    },
    speed: {
      low: {},
      medium: {},
      high: {},
    },
  },

  compoundVariants: [
    {
      status: 'connected',
      speed: 'low',
      css: {
        background: colors.hardwareStrokeAttentionLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeAttentionDark,
        },
      },
    },
    {
      status: 'connected',
      speed: 'medium',
      css: {
        background: colors.hardwareStrokePositiveLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokePositiveDark,
        },
      },
    },
    {
      status: 'connected',
      speed: 'high',
      css: {
        background: colors.hardwareStrokeAlternativeLight,

        [darkThemeSelector]: {
          background: colors.hardwareStrokeAlternativeDark,
        },
      },
    },
  ],
});

const HopConnectionDashes = styled('div', {
  position: 'relative',
  zIndex: 0,
  display: 'flex',
  flexWrap: 'no-wrap',
  justifyContent: 'center',
  gap: '$4',
  overflow: 'hidden',

  variants: {
    layout: {
      row: {
        left: '-50%',
        flexDirection: 'row',
        minWidth: '200%',
        height: '$2',
      },
      column: {
        top: '-50%',
        flexDirection: 'column',
        width: '$2',
        minHeight: '200%',
      },
    },
  },
});

const HopConnectionIcon = styled(Icon, {
  width: '$10',
  height: '$10',
  color: colors.iconNegativeLight,

  [darkThemeSelector]: {
    color: colors.iconNegativeDark,
  },

  variants: {
    variant: {
      alternative: {
        color: colors.hardwareContentAlternativeLight,

        [darkThemeSelector]: {
          color: colors.hardwareContentAlternativeDark,
        },
      },
      attention: {
        color: colors.hardwareContentAttentionLight,

        [darkThemeSelector]: {
          color: colors.hardwareContentAttentionDark,
        },
      },
      neutral: {
        color: colors.hardwareContentNeutralLight,

        [darkThemeSelector]: {
          color: colors.hardwareContentNeutralDark,
        },
      },
      negative: {
        color: colors.hardwareContentNegativeLight,

        [darkThemeSelector]: {
          color: colors.hardwareContentNegativeDark,
        },
      },
      positive: {
        color: colors.hardwareContentPositiveLight,

        [darkThemeSelector]: {
          color: colors.hardwareContentPositiveDark,
        },
      },
    },
  },
});

const HopConnectionBreaker = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1,
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  padding: '$4',
  background: colors.hardwareBgNeutralLight,
  strokeAll: colors.hardwareStrokeNeutralLight,
  borderRadius: '$6',

  [darkThemeSelector]: {
    background: colors.hardwareBgNeutralDark,
    strokeAll: colors.hardwareStrokeNeutralDark,
  },

  variants: {
    variant: {
      attention: {
        background: colors.hardwareBgAttentionLight,
        strokeAll: colors.hardwareStrokeAttentionLight,

        [darkThemeSelector]: {
          background: colors.hardwareBgAttentionDark,
          strokeAll: colors.hardwareStrokeAttentionDark,
        },
      },
      alternative: {
        background: colors.hardwareBgAlternativeLight,
        strokeAll: colors.hardwareStrokeAlternativeLight,

        [darkThemeSelector]: {
          background: colors.hardwareBgAlternativeDark,
          strokeAll: colors.hardwareStrokeAlternativeDark,
        },
      },
      neutral: {
        background: colors.hardwareBgNeutralLight,
        strokeAll: colors.hardwareStrokeNeutralLight,

        [darkThemeSelector]: {
          background: colors.hardwareBgNeutralDark,
          strokeAll: colors.hardwareStrokeNeutralDark,
        },
      },
      negative: {
        background: colors.hardwareBgNegativeLight,
        strokeAll: colors.hardwareStrokeNegativeLight,

        [darkThemeSelector]: {
          background: colors.hardwareBgNegativeDark,
          strokeAll: colors.hardwareStrokeNegativeDark,
        },
      },
      positive: {
        background: colors.hardwareBgPositiveLight,
        strokeAll: colors.hardwareStrokePositiveLight,

        [darkThemeSelector]: {
          background: colors.hardwareBgPositiveDark,
          strokeAll: colors.hardwareStrokePositiveDark,
        },
      },
    },
  },
});

const HopConnectionPosition = styled('div', {
  position: 'relative',
  display: 'flex',

  variants: {
    order: {
      'first-to-last': {},
      'last-to-first': {},
    },
    layout: {
      row: {
        alignItems: 'center',
        height: `$${HOPS_ROW_SIZE + 16}`,
      },
      column: {
        justifyContent: 'center',
        width: `$${HOPS_COLUMN_SIZE + 24}`,
        height: '$28',
      },
    },
  },
});

const HopSubjectLabel = styled(Body, {
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
});

const HopTarget = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '$8 $12',
  borderRadius: '$12',

  variants: {
    isClickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
    selected: {
      true: {
        background: fade(palette.bgBrandLight, 0.7),
        strokeAll: fade(palette.strokeBrandLight, 0.7),

        [darkThemeSelector]: {
          background: fade(palette.bgBrandDark, 0.7),
          strokeAll: fade(palette.strokeBrandDark, 0.7),
        },
      },
    },
  },
});

const HopStagesPort = styled('div', {
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  width: '$22',
  maxWidth: '$22',
  minWidth: '$22',
  margin: '0 -$8',
});

const HopStages = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  variants: {
    layout: {
      row: {
        flexDirection: 'row',
      },
      column: {
        flexDirection: 'column',
      },
    },
  },
});

const HopSubject = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    layout: {
      row: {
        flexDirection: 'column',
        gap: '$4',
      },
      column: {
        flexDirection: 'row',
        gap: '$8',
      },
    },
    isClickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
    selected: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isClickable: true,
      selected: false,
      css: {
        [selectors.hover]: {
          [`& > ${HopTarget}`]: {
            background: fade(palette.bgNeutralLight, 0.7),
            strokeAll: fade(palette.strokeNeutralLight, 0.7),

            [darkThemeSelector]: {
              background: fade(palette.bgNeutralDark, 0.7),
              strokeAll: fade(palette.strokeNeutralDark, 0.7),
            },
          },
        },

        [selectors.focus]: {
          [`& > ${HopTarget}`]: {
            background: fade(palette.bgNeutralLight, 0.7),
            boxShadow: shadows.focusRingLight,

            [darkThemeSelector]: {
              background: fade(palette.bgNeutralDark, 0.7),
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
    },
  ],
});

const speedToCategory = (speed?: number) => {
  if (speed) {
    if (speed < 1000) {
      return 'low';
    }
    if (speed < 10000) {
      return 'medium';
    }
    if (speed < 100000) {
      return 'high';
    }
  }
  return undefined;
};

const speedStatusToVariant = (speed?: number, status?: HopPropStatus) => {
  if (status === 'error') {
    return 'negative';
  }
  if (status === 'disconnected') {
    return 'neutral';
  }
  if (speed && speed < 1000) {
    return 'attention';
  }
  if (speed && speed < 10000) {
    return 'positive';
  }
  if (speed && speed < 100000) {
    return 'alternative';
  }
  return 'neutral';
};
type HopPropIcon = DynamicIconName;
type HopPropLayout = 'row' | 'column';
type HopPropOrder = 'first-to-last' | 'last-to-first';
type HopPropStatus = 'connected' | 'disconnected' | 'error';

interface HopProps {
  badge?: React.ReactNode;
  downlink?: PortProps;
  layout?: HopPropLayout;
  icon?: HopPropIcon;
  label: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  order?: HopPropOrder;
  selected?: boolean;
  showConnection?: boolean;
  speed?: number;
  status?: HopPropStatus;
  uplink?: PortProps;
}

const HopSubjectBadgeContainer = styled(Badge, {
  position: 'relative',
  zIndex: 1,

  variants: {
    layout: {
      row: {
        marginTop: '-$12',
      },
      column: {},
    },
  },
});

function HopSubjectBadge({
  children,
  layout,
  speed,
  status,
}: {
  children: React.ReactNode;
  layout: HopPropLayout;
  speed?: number;
  status?: HopPropStatus;
}) {
  return (
    <HopSubjectBadgeContainer
      ends="pill"
      layout={layout}
      size="small"
      variant={speedStatusToVariant(speed, status)}
    >
      {children}
    </HopSubjectBadgeContainer>
  );
}

function HopConnection({
  layout = 'row',
  order = 'first-to-last',
  status,
  speed,
}: {
  layout: HopPropLayout;
  order?: HopPropOrder;
  status?: HopPropStatus;
  speed?: number;
}) {
  const id = useId();
  return (
    <HopConnectionPosition layout={layout} order={order}>
      <HopConnectionBreaker variant={speedStatusToVariant(speed, status)}>
        {status === 'error' && (
          <HopConnectionIcon icon="cross" variant={speedStatusToVariant(speed, status)} />
        )}
        {status !== 'error' && order === 'last-to-first' && (
          <HopConnectionIcon
            icon={layout === 'row' ? 'arrow-right' : 'arrow-down'}
            variant={speedStatusToVariant(speed, status)}
          />
        )}
        {status !== 'error' && order === 'first-to-last' && (
          <HopConnectionIcon
            icon={layout === 'row' ? 'arrow-left' : 'arrow-up'}
            variant={speedStatusToVariant(speed, status)}
          />
        )}
      </HopConnectionBreaker>
      <HopConnectionDashes layout={layout}>
        {[...Array(status === 'error' ? 100 : 200)].map((_, index) => {
          const key = `hops-${id}-${index}`;
          return (
            <HopConnectionDash
              key={key}
              layout={layout}
              order={order === 'first-to-last' ? 2 : 1}
              status={status}
              speed={speedToCategory(speed)}
            />
          );
        })}
        {status === 'error' &&
          [...Array(100)].map((_, index) => {
            const key = `hops-${id}-${index}`;
            return (
              <HopConnectionDash
                key={key}
                layout={layout}
                order={order === 'first-to-last' ? 1 : 2}
                status="disconnected"
                speed={speedToCategory(speed)}
              />
            );
          })}
      </HopConnectionDashes>
    </HopConnectionPosition>
  );
}

export function Hop<Tag extends React.ElementType>({
  as = 'div' as Tag,
  badge,
  downlink,
  layout = 'row',
  icon,
  label,
  onClick,
  order = 'first-to-last',
  selected,
  showConnection,
  speed,
  status,
  uplink,
  ref,
  ...remaining
}: PolymorphicComponentProps<Tag, HopProps>) {
  const id = useId();
  const isClickable = !!(as === 'a' || as === 'button' || onClick);
  return (
    <React.Fragment key={`hops-${id}-${label}`}>
      {showConnection && order === 'first-to-last' && (
        <HopConnection layout={layout} order={order} status={status} speed={speed} />
      )}
      <HopSubject
        {...(remaining as object)}
        ref={ref}
        isClickable={isClickable}
        as={onClick ? 'button' : as}
        onClick={onClick}
        layout={layout}
        tabIndex={isClickable ? 0 : undefined}
        selected={selected}
      >
        <HopStages layout={layout}>
          <HopStagesPort>{downlink && <Port variant="simple" {...downlink} />}</HopStagesPort>
          {icon && (
            <HopTarget isClickable={isClickable} selected={selected}>
              <DynamicIcon
                icon={icon}
                size={space(layout === 'row' ? HOPS_ROW_SIZE : HOPS_COLUMN_SIZE)}
              />
            </HopTarget>
          )}
          <HopStagesPort>{uplink && <Port variant="simple" {...uplink} />}</HopStagesPort>
        </HopStages>
        {badge && (
          <HopSubjectBadge layout={layout} speed={speed} status={status}>
            {badge}
          </HopSubjectBadge>
        )}
        {label && <HopSubjectLabel>{label}</HopSubjectLabel>}
      </HopSubject>
      {showConnection && order === 'last-to-first' && (
        <HopConnection layout={layout} order={order} status={status} speed={speed} />
      )}
    </React.Fragment>
  );
}

const HopsContainer = styled('div', {
  width: '100%',

  variants: {
    layout: {
      row: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
      },
      column: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
});

type HopsProps = {
  hops: HopProps[];
  layout?: HopPropLayout;
  order?: HopPropOrder;
};

export function Hops({ layout = 'row', hops, order = 'first-to-last' }: HopsProps) {
  const id = useId();
  return (
    <HopsContainer layout={layout}>
      {hops.map((hop, index) => (
        <Hop
          key={`hops-${id}-${hop.label}`}
          {...hop}
          layout={layout}
          order={order}
          showConnection={
            (order === 'first-to-last' && index !== 0) ||
            (order === 'last-to-first' && index !== hops.length - 1)
          }
        />
      ))}
    </HopsContainer>
  );
}
