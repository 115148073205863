import type { ReactNode } from 'react';

import { ControlSizeProvider } from '../../common/control_size';
import { sizing } from '../../common/sizing';
import { Badge } from '../../components/Badge/Badge';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { PrimaryFieldDescription, PrimaryFieldLabel } from './common/FieldLabel';
import { MaybeFieldContainer } from './FieldContainer';

const PrimaryFieldDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const PrimaryFieldHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});

const PrimaryFieldBadges = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const PrimaryFieldLabelling = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
});

const PrimaryFieldControls = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const PrimaryFieldContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  padding: sizing.contentSquish,

  '&:not(:last-child)::after': {
    content: '',
    position: 'absolute',
    display: 'block',
    right: sizing.contentSides,
    bottom: '-0.5px',
    left: sizing.contentSides,
    height: '1px',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

export type FieldHeaderProps = {
  /**
   * A set of badges to display on the field.
   */
  badges?: ReactNode;
  /**
   * A set of controls for the field.
   */
  controls?: ReactNode;
  /**
   * A description for the field. Provides a hint such as specific requirements for what to choose.
   */
  description?: ReactNode;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  /**
   * The label for the field.
   */
  label: ReactNode;
  /**
   * Marks the field as optional with a badge.
   */
  optional?: boolean;
};

export function FieldHeader({
  badges,
  controls,
  description,
  internal,
  label,
  optional,
}: FieldHeaderProps) {
  return (
    <MaybeFieldContainer>
      <PrimaryFieldContainer>
        <PrimaryFieldDetails>
          <PrimaryFieldHeader>
            <PrimaryFieldLabelling>
              <PrimaryFieldLabel>{label}</PrimaryFieldLabel>
              {optional && (
                <Badge ends="card" size="small" variant="neutral">
                  Optional
                </Badge>
              )}
              {internal && <FeatureBadge type="internal" size="x-small" />}
              {badges && (
                <PrimaryFieldBadges>
                  <ControlSizeProvider value="small">{badges}</ControlSizeProvider>
                </PrimaryFieldBadges>
              )}
            </PrimaryFieldLabelling>
            {controls && (
              <PrimaryFieldControls>
                <ControlSizeProvider value="small">{controls}</ControlSizeProvider>
              </PrimaryFieldControls>
            )}
          </PrimaryFieldHeader>
          {description && <PrimaryFieldDescription>{description}</PrimaryFieldDescription>}
        </PrimaryFieldDetails>
      </PrimaryFieldContainer>
    </MaybeFieldContainer>
  );
}
