import React, { useId } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { selectors, transitions } from '../../controls/shared/styles';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { CaptionSansSizes } from '../../text/Caption';
import { SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { isDefined } from '../../utilities/isDefined';
import { HStack } from '../../utilities/Stack/HStack';
import {
  AccessLevel,
  isAccessLevelVisible,
  useAccessLevelContext,
} from '../../utilities/useAccessLevelContext';
import { IconTooltip } from '../Tooltip/Tooltip';

const TabContainer = styled('button', {
  position: 'relative',
  display: 'flex',
  boxShadow: '$$focusRing',
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
    $$focusRing: shadows.focusRingLight,

    [darkThemeSelector]: {
      $$focusRing: shadows.focusRingDark,
    },
  },

  variants: {
    hasAnnotation: {
      true: {
        '@notDesktop': {
          paddingRight: '$6',
        },

        '@desktop': {
          paddingRight: '$4',
        },
      },
    },
    internal: {
      true: {},
      false: {},
    },
    isSelected: {
      true: {
        '&::after': {
          content: '',
          position: 'absolute',
          left: 0,
          right: 0,
          display: 'block',
          backgroundColor: '$$indicatorColor',
          borderRadiusTop: '99em',
        },

        color: colors.headingBrandLight,
        $$annotationBackground: colors.tokenBgBrandLight,
        $$annotationColor: colors.tokenLabelBrandLight,
        $$iconColor: colors.bodyBrandLight,
        $$indicatorColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
          $$annotationBackground: colors.tokenBgBrandDark,
          $$annotationColor: colors.tokenLabelBrandDark,
          $$iconColor: colors.bodyBrandDark,
          $$indicatorColor: colors.iconBrandDark,
        },
      },
      false: {
        color: colors.headingNeutralLight,
        $$annotationBackground: colors.tokenBgNeutralLight,
        $$annotationColor: colors.tokenLabelNeutralLight,
        $$iconColor: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          color: colors.headingNeutralDark,
          $$annotationBackground: colors.tokenBgNeutralDark,
          $$annotationColor: colors.tokenLabelNeutralDark,
          $$iconColor: colors.bodyNeutralDark,
        },

        [selectors.hover]: {
          color: colors.headingNeutralLight,
          $$annotationBackground: colors.tokenBgNeutralLight,
          $$annotationColor: colors.tokenLabelNeutralLight,
          $$iconColor: colors.bodyNeutralLight,

          [darkThemeSelector]: {
            color: colors.headingNeutralDark,
            $$annotationBackground: colors.tokenBgNeutralDark,
            $$annotationColor: colors.tokenLabelNeutralDark,
            $$iconColor: colors.bodyNeutralDark,
          },
        },
      },
    },
    hasSize: {
      'x-small': {
        '&::after': {
          height: '$2',
        },

        '@notDesktop': {
          padding: '$2 $6',
          borderRadius: '$8',

          '&::after': {
            bottom: '-$6',
          },
        },

        '@desktop': {
          padding: '0 $4',
          borderRadius: '$6',

          '&::after': {
            bottom: '-$8',
          },
        },
      },
      small: {
        '&::after': {
          height: '$4',
        },

        '@notDesktop': {
          padding: '$4 $8',
          borderRadius: '$10',

          '&::after': {
            bottom: '-$10',
          },
        },

        '@desktop': {
          padding: '$4 $6',
          borderRadius: '$10',

          '&::after': {
            bottom: '-$8',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        color: colors.internalBodyLight,
        $$annotationBackground: colors.internalBgLight,
        $$annotationColor: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,
        $$indicatorColor: colors.internalIconLight,

        [selectors.hover]: {
          color: colors.internalHeadingLight,
          $$annotationBackground: colors.internalBgLight,
          $$annotationColor: colors.internalBodyLight,
          $$iconColor: colors.internalBodyLight,
        },

        [darkThemeSelector]: {
          color: colors.internalBodyDark,
          $$annotationBackground: colors.internalBgDark,
          $$annotationColor: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
          $$indicatorColor: colors.internalIconDark,

          [selectors.hover]: {
            color: colors.internalHeadingDark,
            $$annotationBackground: colors.internalBgDark,
            $$annotationColor: colors.internalBodyDark,
            $$iconColor: colors.internalBodyDark,
          },
        },
      },
    },
  ],
});

const TabIcon = styled(Icon, {
  color: '$$iconColor',

  variants: {
    hasSize: {
      'x-small': {
        width: '$12',
        height: '$12',
      },

      small: {
        width: '$16',
        height: '$16',
      },
    },
  },
});

const TabAnnotation = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '$$annotationBackground',
  color: '$$annotationColor',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.bold,
  transition: transitions.control,

  variants: {
    hasSize: {
      'x-small': {
        '@notDesktop': {
          ...CaptionSansSizes,
          minWidth: '$20',
          padding: '0 $3',
          borderRadius: '$4',
        },

        '@desktop': {
          ...CaptionSansSizes,
          minWidth: '$18',
          padding: '$1 $3',
          borderRadius: '$4',
        },
      },
      small: {
        '@notDesktop': {
          ...SmallSansSizes,
          minWidth: '$24',
          padding: '$2 $5',
          borderRadius: '$8',
        },

        '@desktop': {
          ...SmallSansSizes,
          minWidth: '$20',
          padding: '$1 $4',
          borderRadius: '$6',
        },
      },
    },
  },
});

type TabPropSize = 'x-small' | 'small';
export interface TabProps {
  /**
   * The level required to access the tab.
   */
  accessLevel?: AccessLevel;
  /**
   * Additional annotation such a result count within the tab.
   * */
  annotation?: React.ReactNode;
  /**
   * Written label for the tab.
   * */
  children?: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  /**
   * Key for the tab.
   */
  key?: string;
  /**
   * Written label for the tab.
   * */
  label?: React.ReactNode;
  /**
   * Handles the onClick event.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Marks the currently selected tab.
   * */
  selected?: boolean;
  /**
   * Size of the tab.
   */
  size?: TabPropSize;
  /**
   * A tooltip to be displayed on hover.
   */
  tooltip?: React.ReactNode;
}

function TabInner<Tag extends React.ElementType>(
  {
    accessLevel: accessLevelProp,
    annotation,
    children,
    icon,
    internal,
    label,
    selected = false,
    size = 'small',
    tooltip,
    ...remaining
  }: PolymorphicComponentProps<Tag, TabProps>,
  forwardedRef: PolymorphicRef<Tag>,
) {
  const accessLevel = useAccessLevelContext(accessLevelProp);
  return (
    <TabContainer
      type="button"
      role="tab"
      aria-selected={selected ? 'true' : 'false'}
      ref={forwardedRef}
      isSelected={selected}
      hasAnnotation={isDefined(annotation)}
      internal={internal || accessLevel === AccessLevel.Internal}
      hasSize={size}
      {...remaining}
    >
      <AlignStack
        direction="row"
        gap={size === 'x-small' ? 4 : 6}
        preset={size === 'x-small' ? 'small' : 'body'}
        start={isDefined(icon) && <TabIcon hasSize={size} icon={icon} />}
        end={
          (isDefined(annotation) || accessLevel || tooltip) && (
            <HStack align="center" spacing={4}>
              {tooltip && <IconTooltip icon="question" size={14} contents={tooltip} />}
              {isDefined(annotation) && <TabAnnotation hasSize={size}>{annotation}</TabAnnotation>}
              {accessLevel && isAccessLevelVisible(accessLevel) && (
                <FeatureBadge type={accessLevel} size="x-small" />
              )}
            </HStack>
          )
        }
      >
        <Text>{label || children}</Text>
      </AlignStack>
    </TabContainer>
  );
}

export const Tab = React.forwardRef(TabInner) as <D extends React.ElementType = 'button'>(
  props: PolymorphicComponentProps<D, TabProps> & { ref?: PolymorphicRef<D> },
) => ReturnType<typeof TabInner>;

const TabsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  alignItems: 'center',
  height: '100%',
});

export type TabsProps = {
  /**
   * Pass `Tab` components into this for automatically laid out tabs.
   * */
  children?: React.ReactNode;
  /**
   * Size of the tabs.
   */
  size?: TabPropSize;
  /**
   * Tabs passed in as an array.
   */
  tabs?: TabProps[];
};

export function Tabs({ children, size, tabs, ...remaining }: TabsProps) {
  const key = useId();
  return (
    <TabsContainer role="tablist" {...remaining}>
      {children}
      {tabs?.map((tab) => (
        <Tab size={size} key={`${key}-tab-${tab.label || tab.children}`} {...tab} />
      ))}
    </TabsContainer>
  );
}
