import { mergeProps, useField } from 'react-aria';

import type { ToggleInputProps } from '../../components/ToggleInput/ToggleInput';
import type { BaseFieldProps } from './common/types';
import { sizing } from '../../common/sizing';
import { Alert } from '../../components/Alert/Alert';
import { ToggleInput } from '../../components/ToggleInput/ToggleInput';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { SecondaryFieldDescription, SecondaryFieldLabel } from './common/FieldLabel';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const SecondaryToggleFieldParent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const SecondaryToggleFieldLabelling = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const SecondaryToggleFieldStart = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const SecondaryToggleFieldEnd = styled('div', {
  maxHeight: '$20',
});

const SecondaryToggleFieldAlert = styled(Alert, {
  padding: sizing.contentSquish,
});

const SecondaryToggleFieldContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
  padding: `$10 ${sizing.contentSides}`,

  '&:not(:last-child)::after': {
    content: '',
    position: 'absolute',
    display: 'block',
    right: sizing.contentSides,
    bottom: '-0.5px',
    left: sizing.contentSides,
    height: '1px',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

export interface SecondaryToggleFieldProps extends BaseFieldProps, ToggleInputProps {
  /**
   * If true, the toggle will be set to an indeterminate state if the `selected` prop is undefined.
   */
  supportsIndeterminate?: boolean;
}

export function SecondaryToggleField(props: SecondaryToggleFieldProps) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    errorMessage = customFieldProps.errorMessage,
    description,
    supportsIndeterminate = false,
    internal,
    ...extraInputProps
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  const indeterminate =
    supportsIndeterminate &&
    'selected' in customInputProps &&
    customInputProps.selected === undefined;

  return (
    <MaybeFieldContainer>
      <SecondaryToggleFieldContainer>
        <SecondaryToggleFieldParent>
          <SecondaryToggleFieldStart>
            <SecondaryToggleFieldLabelling>
              <SecondaryFieldLabel {...labelProps}>{label}</SecondaryFieldLabel>
              {internal && <FeatureBadge type="internal" size="x-small" />}
            </SecondaryToggleFieldLabelling>
            {description && (
              <SecondaryFieldDescription {...descriptionProps}>
                {description}
              </SecondaryFieldDescription>
            )}
          </SecondaryToggleFieldStart>
          <SecondaryToggleFieldEnd>
            <ToggleInput
              controlSize="small"
              indeterminate={indeterminate}
              {...mergeProps(customInputProps, inputProps, extraInputProps)}
            />
          </SecondaryToggleFieldEnd>
        </SecondaryToggleFieldParent>
      </SecondaryToggleFieldContainer>
      {errorMessage && (
        <SecondaryToggleFieldAlert
          {...errorMessageProps}
          type="inline"
          relation="stacked"
          variant="negative"
          heading={filterErrorMessage(errorMessage)}
        />
      )}
    </MaybeFieldContainer>
  );
}
