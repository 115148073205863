import { merge } from 'lodash-es';
import { type CSSProperties, useId } from 'react';

import type { GutterProp } from '../../common/sizing';
import type { CSS } from '../../stitches.config';
import type { PaddingProps } from '../../utilities/shared/Padding';
import type { SpacingScale } from '../../utilities/shared/sizes';
import { fade, palette } from '../../common/colors';
import { darkThemeSelector, keyframes, styled } from '../../stitches.config';
import { gutterCSS } from '../../utilities/shared/Gutter';
import { paddingCSS } from '../../utilities/shared/Padding';
import { spacingCSS } from '../../utilities/shared/Spacing';

const skeletonGrandient = keyframes({
  '0%': {
    backgroundPosition: '0% 50%',
  },
  '50%': {
    backgroundPosition: '100% 50%',
  },
  '100%': {
    backgroundPosition: '0% 50%',
  },
});

const SkeletonContainer = styled('div', {
  backgroundImage: `linear-gradient(-45deg, ${fade(palette.gray300, 0.25)}, ${fade(palette.gray200, 0.1)}, ${fade(palette.gray300, 0.25)})`,
  backgroundSize: '400% 400%',
  animation: `${skeletonGrandient} 2s linear infinite`,

  [darkThemeSelector]: {
    backgroundImage: `linear-gradient(-45deg, ${fade(palette.gray700, 0.35)}, ${fade(palette.gray800, 0.2)}, ${fade(palette.gray700, 0.35)})`,
  },
});

export interface SkeletonProps {
  /**
   * Set the display type of the box modal.
   */
  display?: CSSProperties['display'];
  /**
   * A string to represent the height as a CSS value.
   */
  height?: CSSProperties['height'];
  /**
   * The opacity of the skeletons.
   */
  opacity?: CSSProperties['opacity'];
  /**
   * Set the border radius of the component.
   */
  radius?: CSSProperties['borderRadius'];
  /**
   * A string to represent the width as a CSS value.
   */
  width?: CSSProperties['width'];
}

export function Skeleton({ display, height, opacity, radius, width, ...remaining }: SkeletonProps) {
  return (
    <SkeletonContainer
      style={{
        width,
        height,
        display,
        borderRadius: radius,
        opacity,
      }}
      {...remaining}
    />
  );
}

const SkeletonGroupContainer = styled('div', {
  display: 'flex',
  maxWidth: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
});

type SkeletonGroupProps = {
  /**
   * The context of the skeletons.
   */
  context?: 'application' | 'content';
  /**
   * The direction of the skeletons.
   */
  direction?: CSSProperties['flexDirection'];
  /**
   * The gutter of the skeletons.
   */
  gutter?: GutterProp;
  /**
   * The padding of the skeletons.
   */
  padding?: PaddingProps;
  /**
   * The spacing between the skeletons.
   */
  spacing?: SpacingScale;
  /**
   * The skeletons to render.
   */
  skeletons: SkeletonProps[];
  ref?: React.Ref<HTMLDivElement | null>;
};

export function SkeletonGroup({
  context = 'application',
  direction = 'column',
  gutter = 'none',
  padding,
  spacing = 12,
  skeletons,
  ref: forwardedRef,
  ...remaining
}: SkeletonGroupProps) {
  const id = useId();

  const baseCSS: CSS = {
    flexDirection: direction,
  };
  const mergeCSS = merge(
    baseCSS,
    gutterCSS({ gutter, context }),
    paddingCSS(padding),
    spacingCSS(spacing),
  );

  return (
    <SkeletonGroupContainer ref={forwardedRef} css={mergeCSS} {...remaining}>
      {skeletons.map((skeleton, index) => {
        const opacity =
          skeletons.length > 3
            ? ((skeletons.length - 1 - index) / (skeletons.length - 1)) * 0.9 + 0.1
            : 1;
        const key = index;
        return <Skeleton key={`${id}-skeleton-${key}`} opacity={opacity} {...skeleton} />;
      })}
    </SkeletonGroupContainer>
  );
}
