import type { CSS } from '@stitches/react';
import type { ForwardedRef } from 'react';
import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { ToggleInputProps } from '../../components/ToggleInput/ToggleInput';
import type { BaseFieldProps } from './common/types';
import { sizing } from '../../common/sizing';
import { Alert } from '../../components/Alert/Alert';
import { ToggleInput } from '../../components/ToggleInput/ToggleInput';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import {
  type AccessLevel,
  isAccessLevelVisible,
  useAccessLevelContext,
} from '../../utilities/useAccessLevelContext';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { PrimaryFieldDescription, PrimaryFieldLabel } from './common/FieldLabel';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const PrimaryToggleFieldParent = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
});

const PrimaryToggleFieldLabelling = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$6',
});

const PrimaryToggleFieldStart = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const PrimaryToggleFieldEnd = styled('div', {
  maxHeight: '$20',
});

const PrimaryToggleFieldAlert = styled(Alert, {
  padding: sizing.contentSquish,
});

const PrimaryToggleFieldContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'row',
  gap: '$8',
  padding: sizing.contentSquish,

  '&:not(:last-child)::after': {
    content: '',
    position: 'absolute',
    display: 'block',
    right: sizing.contentSides,
    bottom: '-0.5px',
    left: sizing.contentSides,
    height: '1px',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

export interface PrimaryToggleFieldProps extends BaseFieldProps, ToggleInputProps {
  /**
   * If true, the toggle will be set to an indeterminate state if the `selected` prop is undefined.
   */
  supportsIndeterminate?: boolean;
  containerProps?: { css: CSS };
}

export function PrimaryToggleFieldInner(
  props: PrimaryToggleFieldProps,
  forwardedRef: ForwardedRef<HTMLLabelElement>,
) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    label = customFieldProps.label,
    errorMessage = customFieldProps.errorMessage,
    description,
    supportsIndeterminate = false,
    accessLevel: accessLevelProp,
    internal,
    containerProps,
    ...extraInputProps
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  const accessLevel = useAccessLevelContext(accessLevelProp as AccessLevel);

  const indeterminate =
    supportsIndeterminate &&
    'selected' in customInputProps &&
    customInputProps.selected === undefined;

  return (
    <MaybeFieldContainer>
      <PrimaryToggleFieldContainer {...containerProps}>
        <PrimaryToggleFieldParent>
          <PrimaryToggleFieldStart>
            <PrimaryToggleFieldLabelling>
              <PrimaryFieldLabel {...labelProps}>{label}</PrimaryFieldLabel>
              {accessLevel
                ? isAccessLevelVisible(accessLevel) && (
                    <FeatureBadge type={accessLevel} size="x-small" />
                  )
                : internal && <FeatureBadge type="internal" size="x-small" />}
            </PrimaryToggleFieldLabelling>
            {description && (
              <PrimaryFieldDescription {...descriptionProps}>{description}</PrimaryFieldDescription>
            )}
          </PrimaryToggleFieldStart>
          <PrimaryToggleFieldEnd>
            <ToggleInput
              aria-label={`${label}`}
              controlSize="large"
              indeterminate={indeterminate}
              {...mergeProps(customInputProps, inputProps, extraInputProps, { ref: forwardedRef })}
            />
          </PrimaryToggleFieldEnd>
        </PrimaryToggleFieldParent>
      </PrimaryToggleFieldContainer>
      {errorMessage && (
        <PrimaryToggleFieldAlert
          {...errorMessageProps}
          type="inline"
          relation="stacked"
          variant="negative"
          heading={filterErrorMessage(errorMessage)}
        />
      )}
    </MaybeFieldContainer>
  );
}

export const PrimaryToggleField = React.forwardRef(PrimaryToggleFieldInner);
