import type { AriaCheckboxProps } from '@react-types/checkbox';
import { useObjectRef } from '@react-aria/utils';
import React from 'react';
import { useCheckbox } from 'react-aria';
import { useToggleState } from 'react-stately';

import type { RenameKeys } from '../../types/rename_keys';
import type { Simplify } from '../../types/simplify';
import { Icon } from '../../assets/Icon/Icon';
import {
  iconColor,
  raisedDisabledSelectedStyles,
  raisedDisabledStyles,
  raisedEnabledSelectedStyles,
  raisedEnabledStyles,
  selectors,
  transitions,
} from '../../controls/shared/styles';
import { styled } from '../../stitches.config';

const CheckboxInputCheckmark = styled(Icon, {
  width: '$10',
  height: '$10',
  color: iconColor,
  transition: transitions.control,
});

const CheckboxInputField = styled('input', {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  opacity: 0,
  cursor: 'pointer',

  variants: {
    isDisabled: {
      true: {
        cursor: 'not-allowed',
      },
      false: {},
    },
  },
});

const CheckboxInputContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '$14',
  minWidth: '$14',
  height: '$14',
  minHeight: '$14',
  borderRadius: '$4',
  cursor: 'pointer',
  userSelect: 'none',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    state: {
      initial: {},
      checked: {},
      indeterminate: {},
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      false: {},
    },
  },

  compoundVariants: [
    {
      state: 'initial',
      isDisabled: false,
      css: raisedEnabledStyles,
    },
    {
      state: 'checked',
      isDisabled: false,
      css: raisedEnabledSelectedStyles,
    },
    {
      state: 'indeterminate',
      isDisabled: false,
      css: { ...raisedEnabledStyles },
    },
    {
      state: 'initial',
      isDisabled: true,
      css: raisedDisabledStyles,
    },
    {
      state: 'checked',
      isDisabled: true,
      css: raisedDisabledSelectedStyles,
    },
    {
      state: 'indeterminate',
      isDisabled: true,
      css: raisedDisabledStyles,
    },
  ],
});

export type CheckboxInputProps = Simplify<
  RenameKeys<
    AriaCheckboxProps,
    {
      isDisabled: 'disabled';
      isSelected: 'checked';
      isIndeterminate: 'indeterminate';
    }
  >
> & {
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  ref?: React.Ref<HTMLInputElement | null>;
};

export function CheckboxInput({ ref, ...props }: CheckboxInputProps) {
  const { disabled = false, indeterminate = false, checked } = props;
  const ariaProps = {
    ...props,
    isSelected: checked,
    isIndeterminate: indeterminate,
    isDisabled: disabled,
  };

  const toggleState = useToggleState(ariaProps);
  const inputRef = useObjectRef<HTMLInputElement>(ref);
  const { inputProps } = useCheckbox(ariaProps, toggleState, inputRef);

  let state: 'checked' | 'indeterminate' | 'initial';
  if (toggleState.isSelected) {
    state = 'checked';
  } else if (indeterminate) {
    state = 'indeterminate';
  } else {
    state = 'initial';
  }

  return (
    <CheckboxInputContainer state={state} isDisabled={disabled}>
      {state !== 'initial' && (
        <CheckboxInputCheckmark icon={state === 'checked' ? 'checkmark' : 'minus'} />
      )}
      <CheckboxInputField
        isDisabled={disabled}
        type="checkbox"
        {...inputProps}
        onClick={props.onClick}
      />
    </CheckboxInputContainer>
  );
}
