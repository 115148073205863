import { useMemo, useState } from 'react';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';
import { type LinkProps, type To, Link } from 'react-router-dom';

export function EnhancedReactRouterLink({
  to,
  hotkeyTos,
  ...props
}: LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    hotkeyTos?: Record<string, To>;
  }) {
  const [hotkeyMap, setHotkeyMap] = useState<Map<string, boolean>>(new Map());
  const hotkeyKeys = useMemo(() => Object.keys(hotkeyTos ?? {}), [hotkeyTos]);
  useHotkeys(
    hotkeyKeys,
    () => {
      setHotkeyMap(new Map(hotkeyKeys.map((key) => [key, isHotkeyPressed(key)])));
    },
    {
      enabled: hotkeyKeys.length > 0,
      keyup: true,
      keydown: true,
    },
    [hotkeyKeys],
  );

  const computedTo = useMemo(() => {
    const hotkeyTo = Array.from(hotkeyMap.entries()).find(([, isPressed]) => isPressed);
    return hotkeyTos && hotkeyTo && hotkeyTo[0] in hotkeyTos ? hotkeyTos[hotkeyTo[0]] : to;
  }, [to, hotkeyTos, hotkeyMap]);

  return <Link to={computedTo} {...props} />;
}
