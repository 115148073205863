import type { ComponentProps } from 'react';
import React from 'react';

import type { StatProps } from '../../components/Stats/Stats';
import type { PolymorphicComponentProps } from '../../utilities/types/polymorphicAsProp';
import { selectors } from '../../common/selectors';
import { sizing } from '../../common/sizing';
import { Stats } from '../../components/Stats/Stats';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';

const CardHeadline = styled(Body, {
  color: '$$headlineColor',
  fontWeight: fontWeights.bold,
});

const CardSubline = styled(Small, {
  color: '$$sublineColor',
});

const CardHeader = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  minWidth: '$320',
  padding: sizing.contentSquish,
  borderRadius: '$10',
  strokeAll: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    strokeAll: colors.strokeNeutralDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.bgNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.bgNeutralDark,
    },
  },

  [selectors.focus]: {
    boxShadow: shadows.focusRingLight,
    outline: 'none',

    [darkThemeSelector]: {
      boxShadow: shadows.focusRingDark,
    },
  },

  variants: {
    selected: {
      true: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,
          color: colors.bodyBrandLight,
          $$headlineColor: colors.headingBrandLight,
          $$sublineColor: colors.bodyBrandLight,

          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
            color: colors.bodyBrandDark,
            $$headlineColor: colors.headingBrandDark,
            $$sublineColor: colors.bodyBrandDark,
          },
        },
      },
      false: {},
    },
  },
});

type CardProps = {
  headline: React.ReactNode;
  subline?: React.ReactNode;
  stats?: StatProps[];
  selected?: boolean;
} & ComponentProps<typeof CardContainer>;

export function Card<Tag extends React.ElementType>({
  headline,
  subline,
  stats,
  selected,
  ref,
  ...remaining
}: PolymorphicComponentProps<Tag, CardProps>) {
  return (
    <CardContainer selected={selected} {...remaining} ref={ref}>
      <CardHeader>
        <CardHeadline weight="bold">{headline}</CardHeadline>
        <CardSubline>{subline}</CardSubline>
      </CardHeader>
      {stats && <Stats gutter="none" selected={selected} size="small" stats={stats} />}
    </CardContainer>
  );
}

const CardsContainer = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  padding: sizing.squish,
});

export function Cards({ children }: { children: React.ReactNode }) {
  return <CardsContainer>{children}</CardsContainer>;
}
