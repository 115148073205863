import type { ComponentProps } from 'react';
import React, { useLayoutEffect, useRef, useState } from 'react';

import type { SpacingScale } from '../../utilities/shared/sizes';
import type { Breakpoint } from '../../utilities/useViewport';
import type {
  ShellContentProps,
  ShellFooterProps,
  ShellHeaderProps,
  ShellProps,
} from '../Shell/Shell';
import { sizing } from '../../common/sizing';
import { zIndexes } from '../../common/z_indexes';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { StuckProvider } from '../../utilities/useStuck';
import { useViewport } from '../../utilities/useViewport';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

const PaneHeaderContainer = styled(ShellHeader);

export type PaneHeaderProps = ShellHeaderProps;

export function PaneHeader({ ...remaining }: PaneHeaderProps) {
  return <PaneHeaderContainer {...remaining} />;
}

const PaneContentStuckInnerPosition = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: 'fit-content',

  variants: {
    stuck: {
      true: {
        background: colors.bgApplicationLight,
        boxShadow: shadows.stuckLight,

        [darkThemeSelector]: {
          background: colors.bgApplicationDark,
          boxShadow: shadows.stuckDark,
        },
      },
      false: {
        '&::after': {
          content: '',
          position: 'absolute',
          right: sizing.sides,
          bottom: '-0.5px',
          left: sizing.sides,
          display: 'block',
          height: '$1',
          background: colors.strokeNeutralLight,

          [darkThemeSelector]: {
            background: colors.strokeNeutralDark,
          },
        },
      },
    },
  },
});

const PaneContentStuckInnerContainer = styled('div', {
  display: 'flex',
  width: '100%',
});

type PaneContentGlanceProps = {
  /**
   * Provide the content for the glance.
   */
  children: React.ReactNode;
  /**
   * Boolean to determine if the element is stuck or not.
   */
  stuck?: boolean;

  ref?: React.Ref<HTMLDivElement | null>;
};

export function PaneContentStuckInner({ children, stuck, ref }: PaneContentGlanceProps) {
  return (
    <StuckProvider value={stuck}>
      <PaneContentStuckInnerContainer ref={ref}>
        <PaneContentStuckInnerPosition stuck={stuck}>{children}</PaneContentStuckInnerPosition>
      </PaneContentStuckInnerContainer>
    </StuckProvider>
  );
}

const usePaneContentStuck = () => {
  const [isOutOfView, setIsOutOfView] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  // Make the sticky header visible when 70% of the "real" header is out of view.
  useLayoutEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      // ensures that the element is rendered with content without display: none; (a result of AppSkeleton)
      if (ref.current?.clientHeight) {
        setIsOutOfView(!entries[0].isIntersecting);
      }
    };

    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.3,
      rootMargin: '0px 0px 0px 0px',
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer?.disconnect();
    };
  }, []);

  return { ref, isOutOfView };
};

const PaneContentStuckZero = styled('div', {
  position: 'sticky',
  zIndex: zIndexes.paneStuck,
  top: 0,
  height: 0,
  opacity: 0,
  transition: 'opacity 200ms ease-in-out',

  variants: {
    visible: {
      true: {
        display: 'flex',
        opacity: 1,
      },
      false: {
        opacity: 0,
        pointerEvents: 'none',
      },
    },
  },
});

export function PaneContentStuck({
  spacing,
  ...props
}: { spacing: SpacingScale } & PaneContentGlanceProps) {
  const { ref, isOutOfView } = usePaneContentStuck();

  return (
    <>
      <PaneContentStuckZero
        visible={isOutOfView}
        aria-hidden="true"
        style={{ marginTop: `-${spacing}px` }}
      >
        <PaneContentStuckInner {...props} stuck />
      </PaneContentStuckZero>
      <PaneContentStuckInner {...props} stuck={false} ref={ref} />
    </>
  );
}

const PaneContentGlance = styled('div', {
  position: 'relative',
  padding: `${sizing.contentEnds} ${sizing.sides}`,

  '&::after': {
    content: '',
    position: 'absolute',
    right: sizing.sides,
    bottom: '-0.5px',
    left: sizing.sides,
    display: 'block',
    height: '$1',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

const PaneContentControls = styled('div');

const PaneContentContainer = styled(ShellContent);

export type PaneContentProps = ShellContentProps & {
  controls?: React.ReactNode;
  glance?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement | null>;
};

export function PaneContent({
  background,
  children,
  controls,
  glance,
  gutter = 'none',
  spacing = 16,
  ref,
  ...remaining
}: PaneContentProps) {
  return (
    <PaneContentContainer
      ref={ref}
      background={background}
      gutter={gutter}
      spacing={spacing}
      {...remaining}
    >
      {(controls || glance) && (
        <PaneContentStuck spacing={spacing}>
          {glance && <PaneContentGlance>{glance}</PaneContentGlance>}
          {controls && <PaneContentControls>{controls}</PaneContentControls>}
        </PaneContentStuck>
      )}
      {children}
    </PaneContentContainer>
  );
}

const PaneFooterContainer = styled(ShellFooter);

export type PaneFooterProps = ShellFooterProps & {
  /**
   * Provide primary actions for your drawer.
   */
  actions?: React.ReactNode;
  /**
   * Fallback to `children` if `actions` or `secondaryActions` are not provided.
   */
  children?: React.ReactNode;
  /**
   * Provide secondary actions, positioned at the start of the footer.
   */
  secondaryActions?: React.ReactNode;
} & ComponentProps<typeof PaneFooterContainer>;

export function PaneFooter({ actions, children, secondaryActions, ...remaining }: PaneFooterProps) {
  return <PaneFooterContainer start={secondaryActions} end={actions || children} {...remaining} />;
}

export const PaneContainer = styled(Shell, {
  gridArea: 'pane',
  width: '100%',

  variants: {
    preset: {
      full: {},
      intermediate: {
        '@mobile': {
          height: '100%',
        },

        '@notMobile': {
          paddingTop: '$32',
        },
      },
      narrow: {
        '@mobile': {
          height: '100%',
        },

        '@notMobile': {
          paddingTop: '$32',
        },
      },
    },
  },
});

export type ShellPresetProp = 'narrow' | 'intermediate' | 'full';

export type PaneProps = ShellProps & {
  preset?: ShellPresetProp;
} & ComponentProps<typeof PaneContainer>;

const getPaneMaxWidth = (preset: ShellPresetProp, breakpoint: Breakpoint) => {
  switch (preset) {
    case 'intermediate':
      return breakpoint !== 'mobile' ? 1024 : undefined;
    case 'narrow':
      return breakpoint !== 'mobile' ? 760 : undefined;
    default:
      return 'full';
  }
};

export function Pane({ children, preset = 'full', ...remaining }: PaneProps) {
  const { breakpoint } = useViewport();
  return (
    <PaneContainer
      preset={preset}
      maxWidth={getPaneMaxWidth(preset, breakpoint)}
      widthMode={preset === 'full' ? 'full' : 'centered'}
      {...remaining}
    >
      {children}
    </PaneContainer>
  );
}
