import { createContext, useContext } from 'react';

export type StuckProp = boolean;

type Stuck = StuckProp;

const StuckContext = createContext<Stuck | undefined>(undefined);

export const StuckProvider = StuckContext.Provider;

export function useStuck(controlledValue?: Stuck, defaultValue: Stuck = false) {
  const stuck = useContext(StuckContext);
  return controlledValue ?? stuck ?? defaultValue;
}
