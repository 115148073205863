const layers = {
  base: 0,
  dock: 100,
  sidebar: 200,
  stage: 300,
  pane: 400,
  drawer: 500,
  overlay: 600,
  dialog: 700,
  tooltip: 800,
  alert: 900,
} as const;

export const zIndexes = {
  // Base
  base: layers.base,

  // Dock
  dock: layers.dock,
  dockFloating: layers.dock + 10,

  // Sidebar
  sidebar: layers.sidebar,
  sidebarFloating: layers.sidebar + 10,
  sidebarPop: layers.pane + 1,

  // Stage
  stage: layers.stage,

  // Panes
  pane: layers.pane,
  paneDrawer: layers.pane + 1,
  paneStuck: layers.pane + 2,

  // Drawer
  drawer: layers.drawer,
  drawerContent: layers.drawer + 1,
  drawerWash: layers.drawer - 1,

  // Overlays
  overlay: layers.overlay,
  overlayContent: layers.overlay + 1,
  overlayWash: layers.overlay - 1,

  // Dialogs
  dialog: layers.dialog,
  dialogContent: layers.dialog + 1,
  dialogWash: layers.dialog - 1,

  // Tooltips
  tooltip: layers.tooltip,

  // Alerts
  alert: layers.alert,
};
