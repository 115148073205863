import type { AriaPopoverProps } from 'react-aria';
import type { OverlayTriggerState } from 'react-stately';
import React from 'react';
import { DismissButton, FocusScope, mergeProps, useDialog, useModal, useOverlay } from 'react-aria';

import { contentStyles } from '../../common/menus';
import { styled } from '../../stitches.config';

const Container = styled('div', contentStyles, {
  padding: '$4',
  overflow: 'auto',
});

interface MultiComboBoxPopoverProps {
  popoverRef: React.RefObject<HTMLDivElement | null>;
  children: React.ReactNode;
  onClose: () => void;
  state: OverlayTriggerState;
  placement?: AriaPopoverProps['placement'];
  style?: React.CSSProperties;
}

export function MultiComboBoxPopover({
  popoverRef,
  children,
  state,
  style,
  onClose,
  ...otherProps
}: MultiComboBoxPopoverProps) {
  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps } = useOverlay(
    {
      onClose,
      isOpen: state.isOpen,
      isDismissable: true,
      shouldCloseOnInteractOutside(element) {
        // clicking on an existing tag should remove the tag
        if (element.closest('[role="gridcell"]')) {
          return false;
        }

        return true;
      },
    },
    popoverRef,
  );

  const { modalProps } = useModal();
  const { dialogProps } = useDialog({}, popoverRef);

  return (
    <FocusScope restoreFocus>
      <Container
        {...mergeProps(overlayProps, dialogProps, otherProps, modalProps)}
        style={{
          ...style,
          // if the popover appears in a Portal while a Dialog is open, the body tag will have
          // pointer events disabled. this overrides that behavior so that the popover can be
          // interacted with while the dialog is open.
          pointerEvents: 'auto',
        }}
        ref={popoverRef}
      >
        {children}
        <DismissButton onDismiss={onClose} />
      </Container>
    </FocusScope>
  );
}
